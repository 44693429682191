import type { CollectionRegistry, SchemaRegistry } from '@mcschema/core'
import {
	BooleanNode,
	Case,
	ChoiceNode,
	ListNode,
	MapNode,
	Mod,
	NumberNode,
	ObjectNode,
	Opt,
	Reference as RawReference,
	StringNode as RawStringNode,
	Switch,
} from '@mcschema/core'
import { Tag } from '../../common/common.js'

const ID = 'shardborne'

export function initShardborne(schemas: SchemaRegistry, collections: CollectionRegistry) {
	const Reference = RawReference.bind(undefined, schemas)
	const StringNode = RawStringNode.bind(undefined, collections)

	collections.register('shardborne:features', [
		'shardborne:custom_npc',
		'shardborne:processor_rules',
		'shardborne:theme',
	])

	collections.register(`${ID}:npcs`, [
		'shardborne:wisp',
		'shardborne:meld',
		'shardborne:sage',
		'shardborne:juuno',
		'shardborne:aldhor',
	])

	schemas.register(`${ID}:npc_type`, StringNode({ validator: 'resource', params: { pool: `${ID}:npcs` as any } }))

	collections.register('block', [
		'create:acacia_window',
		'create:acacia_window_pane',
		'create:adjustable_chain_gearshift',
		'create:analog_lever',
		'create:andesite_alloy_block',
		'create:andesite_bars',
		'create:andesite_belt_funnel',
		'create:andesite_casing',
		'create:andesite_door',
		'create:andesite_encased_cogwheel',
		'create:andesite_encased_large_cogwheel',
		'create:andesite_encased_shaft',
		'create:andesite_funnel',
		'create:andesite_ladder',
		'create:andesite_pillar',
		'create:andesite_scaffolding',
		'create:andesite_tunnel',
		'create:asurine',
		'create:asurine_pillar',
		'create:basin',
		'create:belt',
		'create:birch_window',
		'create:birch_window_pane',
		'create:black_nixie_tube',
		'create:black_sail',
		'create:black_seat',
		'create:black_toolbox',
		'create:black_valve_handle',
		'create:blaze_burner',
		'create:blue_nixie_tube',
		'create:blue_sail',
		'create:blue_seat',
		'create:blue_toolbox',
		'create:blue_valve_handle',
		'create:brass_bars',
		'create:brass_belt_funnel',
		'create:brass_block',
		'create:brass_casing',
		'create:brass_door',
		'create:brass_encased_cogwheel',
		'create:brass_encased_large_cogwheel',
		'create:brass_encased_shaft',
		'create:brass_funnel',
		'create:brass_ladder',
		'create:brass_scaffolding',
		'create:brass_tunnel',
		'create:brown_nixie_tube',
		'create:brown_sail',
		'create:brown_seat',
		'create:brown_toolbox',
		'create:brown_valve_handle',
		'create:calcite_pillar',
		'create:cart_assembler',
		'create:chocolate',
		'create:chute',
		'create:clipboard',
		'create:clockwork_bearing',
		'create:clutch',
		'create:cogwheel',
		'create:content_observer',
		'create:contraption_controls',
		'create:controller_rail',
		'create:controls',
		'create:copper_backtank',
		'create:copper_bars',
		'create:copper_casing',
		'create:copper_door',
		'create:copper_ladder',
		'create:copper_scaffolding',
		'create:copper_shingle_slab',
		'create:copper_shingle_stairs',
		'create:copper_shingles',
		'create:copper_tile_slab',
		'create:copper_tile_stairs',
		'create:copper_tiles',
		'create:copper_valve_handle',
		'create:copycat_bars',
		'create:copycat_base',
		'create:copycat_panel',
		'create:copycat_step',
		'create:creative_crate',
		'create:creative_fluid_tank',
		'create:creative_motor',
		'create:crimsite',
		'create:crimsite_pillar',
		'create:crimson_window',
		'create:crimson_window_pane',
		'create:crushing_wheel',
		'create:crushing_wheel_controller',
		'create:cuckoo_clock',
		'create:cut_andesite',
		'create:cut_andesite_brick_slab',
		'create:cut_andesite_brick_stairs',
		'create:cut_andesite_brick_wall',
		'create:cut_andesite_bricks',
		'create:cut_andesite_slab',
		'create:cut_andesite_stairs',
		'create:cut_andesite_wall',
		'create:cut_asurine',
		'create:cut_asurine_brick_slab',
		'create:cut_asurine_brick_stairs',
		'create:cut_asurine_brick_wall',
		'create:cut_asurine_bricks',
		'create:cut_asurine_slab',
		'create:cut_asurine_stairs',
		'create:cut_asurine_wall',
		'create:cut_calcite',
		'create:cut_calcite_brick_slab',
		'create:cut_calcite_brick_stairs',
		'create:cut_calcite_brick_wall',
		'create:cut_calcite_bricks',
		'create:cut_calcite_slab',
		'create:cut_calcite_stairs',
		'create:cut_calcite_wall',
		'create:cut_crimsite',
		'create:cut_crimsite_brick_slab',
		'create:cut_crimsite_brick_stairs',
		'create:cut_crimsite_brick_wall',
		'create:cut_crimsite_bricks',
		'create:cut_crimsite_slab',
		'create:cut_crimsite_stairs',
		'create:cut_crimsite_wall',
		'create:cut_deepslate',
		'create:cut_deepslate_brick_slab',
		'create:cut_deepslate_brick_stairs',
		'create:cut_deepslate_brick_wall',
		'create:cut_deepslate_bricks',
		'create:cut_deepslate_slab',
		'create:cut_deepslate_stairs',
		'create:cut_deepslate_wall',
		'create:cut_diorite',
		'create:cut_diorite_brick_slab',
		'create:cut_diorite_brick_stairs',
		'create:cut_diorite_brick_wall',
		'create:cut_diorite_bricks',
		'create:cut_diorite_slab',
		'create:cut_diorite_stairs',
		'create:cut_diorite_wall',
		'create:cut_dripstone',
		'create:cut_dripstone_brick_slab',
		'create:cut_dripstone_brick_stairs',
		'create:cut_dripstone_brick_wall',
		'create:cut_dripstone_bricks',
		'create:cut_dripstone_slab',
		'create:cut_dripstone_stairs',
		'create:cut_dripstone_wall',
		'create:cut_granite',
		'create:cut_granite_brick_slab',
		'create:cut_granite_brick_stairs',
		'create:cut_granite_brick_wall',
		'create:cut_granite_bricks',
		'create:cut_granite_slab',
		'create:cut_granite_stairs',
		'create:cut_granite_wall',
		'create:cut_limestone',
		'create:cut_limestone_brick_slab',
		'create:cut_limestone_brick_stairs',
		'create:cut_limestone_brick_wall',
		'create:cut_limestone_bricks',
		'create:cut_limestone_slab',
		'create:cut_limestone_stairs',
		'create:cut_limestone_wall',
		'create:cut_ochrum',
		'create:cut_ochrum_brick_slab',
		'create:cut_ochrum_brick_stairs',
		'create:cut_ochrum_brick_wall',
		'create:cut_ochrum_bricks',
		'create:cut_ochrum_slab',
		'create:cut_ochrum_stairs',
		'create:cut_ochrum_wall',
		'create:cut_scorchia',
		'create:cut_scorchia_brick_slab',
		'create:cut_scorchia_brick_stairs',
		'create:cut_scorchia_brick_wall',
		'create:cut_scorchia_bricks',
		'create:cut_scorchia_slab',
		'create:cut_scorchia_stairs',
		'create:cut_scorchia_wall',
		'create:cut_scoria',
		'create:cut_scoria_brick_slab',
		'create:cut_scoria_brick_stairs',
		'create:cut_scoria_brick_wall',
		'create:cut_scoria_bricks',
		'create:cut_scoria_slab',
		'create:cut_scoria_stairs',
		'create:cut_scoria_wall',
		'create:cut_tuff',
		'create:cut_tuff_brick_slab',
		'create:cut_tuff_brick_stairs',
		'create:cut_tuff_brick_wall',
		'create:cut_tuff_bricks',
		'create:cut_tuff_slab',
		'create:cut_tuff_stairs',
		'create:cut_tuff_wall',
		'create:cut_veridium',
		'create:cut_veridium_brick_slab',
		'create:cut_veridium_brick_stairs',
		'create:cut_veridium_brick_wall',
		'create:cut_veridium_bricks',
		'create:cut_veridium_slab',
		'create:cut_veridium_stairs',
		'create:cut_veridium_wall',
		'create:cyan_nixie_tube',
		'create:cyan_sail',
		'create:cyan_seat',
		'create:cyan_toolbox',
		'create:cyan_valve_handle',
		'create:dark_oak_window',
		'create:dark_oak_window_pane',
		'create:deepslate_pillar',
		'create:deepslate_zinc_ore',
		'create:deployer',
		'create:depot',
		'create:diorite_pillar',
		'create:display_board',
		'create:display_link',
		'create:dripstone_pillar',
		'create:elevator_contact',
		'create:elevator_pulley',
		'create:encased_chain_drive',
		'create:encased_fan',
		'create:encased_fluid_pipe',
		'create:experience_block',
		'create:exposed_copper_shingle_slab',
		'create:exposed_copper_shingle_stairs',
		'create:exposed_copper_shingles',
		'create:exposed_copper_tile_slab',
		'create:exposed_copper_tile_stairs',
		'create:exposed_copper_tiles',
		'create:fake_track',
		'create:fluid_pipe',
		'create:fluid_tank',
		'create:fluid_valve',
		'create:flywheel',
		'create:framed_glass',
		'create:framed_glass_door',
		'create:framed_glass_pane',
		'create:framed_glass_trapdoor',
		'create:gantry_carriage',
		'create:gantry_shaft',
		'create:gearbox',
		'create:gearshift',
		'create:glass_fluid_pipe',
		'create:granite_pillar',
		'create:gray_nixie_tube',
		'create:gray_sail',
		'create:gray_seat',
		'create:gray_toolbox',
		'create:gray_valve_handle',
		'create:green_nixie_tube',
		'create:green_sail',
		'create:green_seat',
		'create:green_toolbox',
		'create:green_valve_handle',
		'create:hand_crank',
		'create:haunted_bell',
		'create:honey',
		'create:horizontal_framed_glass',
		'create:horizontal_framed_glass_pane',
		'create:hose_pulley',
		'create:industrial_iron_block',
		'create:item_drain',
		'create:item_vault',
		'create:jungle_window',
		'create:jungle_window_pane',
		'create:large_bogey',
		'create:large_cogwheel',
		'create:large_water_wheel',
		'create:layered_andesite',
		'create:layered_asurine',
		'create:layered_calcite',
		'create:layered_crimsite',
		'create:layered_deepslate',
		'create:layered_diorite',
		'create:layered_dripstone',
		'create:layered_granite',
		'create:layered_limestone',
		'create:layered_ochrum',
		'create:layered_scorchia',
		'create:layered_scoria',
		'create:layered_tuff',
		'create:layered_veridium',
		'create:lectern_controller',
		'create:light_blue_nixie_tube',
		'create:light_blue_sail',
		'create:light_blue_seat',
		'create:light_blue_toolbox',
		'create:light_blue_valve_handle',
		'create:light_gray_nixie_tube',
		'create:light_gray_sail',
		'create:light_gray_seat',
		'create:light_gray_toolbox',
		'create:light_gray_valve_handle',
		'create:lime_nixie_tube',
		'create:lime_sail',
		'create:lime_seat',
		'create:lime_toolbox',
		'create:lime_valve_handle',
		'create:limestone',
		'create:limestone_pillar',
		'create:linear_chassis',
		'create:lit_blaze_burner',
		'create:magenta_nixie_tube',
		'create:magenta_sail',
		'create:magenta_seat',
		'create:magenta_toolbox',
		'create:magenta_valve_handle',
		'create:mangrove_window',
		'create:mangrove_window_pane',
		'create:mechanical_arm',
		'create:mechanical_bearing',
		'create:mechanical_crafter',
		'create:mechanical_drill',
		'create:mechanical_harvester',
		'create:mechanical_mixer',
		'create:mechanical_piston',
		'create:mechanical_piston_head',
		'create:mechanical_plough',
		'create:mechanical_press',
		'create:mechanical_pump',
		'create:mechanical_roller',
		'create:mechanical_saw',
		'create:metal_bracket',
		'create:metal_girder',
		'create:metal_girder_encased_shaft',
		'create:millstone',
		'create:minecart_anchor',
		'create:mysterious_cuckoo_clock',
		'create:netherite_backtank',
		'create:nixie_tube',
		'create:nozzle',
		'create:oak_window',
		'create:oak_window_pane',
		'create:ochrum',
		'create:ochrum_pillar',
		'create:orange_sail',
		'create:orange_seat',
		'create:orange_toolbox',
		'create:orange_valve_handle',
		'create:ornate_iron_window',
		'create:ornate_iron_window_pane',
		'create:oxidized_copper_shingle_slab',
		'create:oxidized_copper_shingle_stairs',
		'create:oxidized_copper_shingles',
		'create:oxidized_copper_tile_slab',
		'create:oxidized_copper_tile_stairs',
		'create:oxidized_copper_tiles',
		'create:peculiar_bell',
		'create:pink_nixie_tube',
		'create:pink_sail',
		'create:pink_seat',
		'create:pink_toolbox',
		'create:pink_valve_handle',
		'create:piston_extension_pole',
		'create:placard',
		'create:polished_cut_andesite',
		'create:polished_cut_andesite_slab',
		'create:polished_cut_andesite_stairs',
		'create:polished_cut_andesite_wall',
		'create:polished_cut_asurine',
		'create:polished_cut_asurine_slab',
		'create:polished_cut_asurine_stairs',
		'create:polished_cut_asurine_wall',
		'create:polished_cut_calcite',
		'create:polished_cut_calcite_slab',
		'create:polished_cut_calcite_stairs',
		'create:polished_cut_calcite_wall',
		'create:polished_cut_crimsite',
		'create:polished_cut_crimsite_slab',
		'create:polished_cut_crimsite_stairs',
		'create:polished_cut_crimsite_wall',
		'create:polished_cut_deepslate',
		'create:polished_cut_deepslate_slab',
		'create:polished_cut_deepslate_stairs',
		'create:polished_cut_deepslate_wall',
		'create:polished_cut_diorite',
		'create:polished_cut_diorite_slab',
		'create:polished_cut_diorite_stairs',
		'create:polished_cut_diorite_wall',
		'create:polished_cut_dripstone',
		'create:polished_cut_dripstone_slab',
		'create:polished_cut_dripstone_stairs',
		'create:polished_cut_dripstone_wall',
		'create:polished_cut_granite',
		'create:polished_cut_granite_slab',
		'create:polished_cut_granite_stairs',
		'create:polished_cut_granite_wall',
		'create:polished_cut_limestone',
		'create:polished_cut_limestone_slab',
		'create:polished_cut_limestone_stairs',
		'create:polished_cut_limestone_wall',
		'create:polished_cut_ochrum',
		'create:polished_cut_ochrum_slab',
		'create:polished_cut_ochrum_stairs',
		'create:polished_cut_ochrum_wall',
		'create:polished_cut_scorchia',
		'create:polished_cut_scorchia_slab',
		'create:polished_cut_scorchia_stairs',
		'create:polished_cut_scorchia_wall',
		'create:polished_cut_scoria',
		'create:polished_cut_scoria_slab',
		'create:polished_cut_scoria_stairs',
		'create:polished_cut_scoria_wall',
		'create:polished_cut_tuff',
		'create:polished_cut_tuff_slab',
		'create:polished_cut_tuff_stairs',
		'create:polished_cut_tuff_wall',
		'create:polished_cut_veridium',
		'create:polished_cut_veridium_slab',
		'create:polished_cut_veridium_stairs',
		'create:polished_cut_veridium_wall',
		'create:portable_fluid_interface',
		'create:portable_storage_interface',
		'create:powered_latch',
		'create:powered_shaft',
		'create:powered_toggle_latch',
		'create:pulley_magnet',
		'create:pulse_extender',
		'create:pulse_repeater',
		'create:purple_nixie_tube',
		'create:purple_sail',
		'create:purple_seat',
		'create:purple_toolbox',
		'create:purple_valve_handle',
		'create:radial_chassis',
		'create:railway_casing',
		'create:raw_zinc_block',
		'create:red_nixie_tube',
		'create:red_sail',
		'create:red_seat',
		'create:red_toolbox',
		'create:red_valve_handle',
		'create:redstone_contact',
		'create:redstone_link',
		'create:refined_radiance_casing',
		'create:rope',
		'create:rope_pulley',
		'create:rose_quartz_block',
		'create:rose_quartz_lamp',
		'create:rose_quartz_tiles',
		'create:rotation_speed_controller',
		'create:sail_frame',
		'create:schematic_table',
		'create:schematicannon',
		'create:scorchia',
		'create:scorchia_pillar',
		'create:scoria',
		'create:scoria_pillar',
		'create:secondary_linear_chassis',
		'create:sequenced_gearshift',
		'create:shadow_steel_casing',
		'create:shaft',
		'create:small_andesite_brick_slab',
		'create:small_andesite_brick_stairs',
		'create:small_andesite_brick_wall',
		'create:small_andesite_bricks',
		'create:small_asurine_brick_slab',
		'create:small_asurine_brick_stairs',
		'create:small_asurine_brick_wall',
		'create:small_asurine_bricks',
		'create:small_bogey',
		'create:small_calcite_brick_slab',
		'create:small_calcite_brick_stairs',
		'create:small_calcite_brick_wall',
		'create:small_calcite_bricks',
		'create:small_crimsite_brick_slab',
		'create:small_crimsite_brick_stairs',
		'create:small_crimsite_brick_wall',
		'create:small_crimsite_bricks',
		'create:small_deepslate_brick_slab',
		'create:small_deepslate_brick_stairs',
		'create:small_deepslate_brick_wall',
		'create:small_deepslate_bricks',
		'create:small_diorite_brick_slab',
		'create:small_diorite_brick_stairs',
		'create:small_diorite_brick_wall',
		'create:small_diorite_bricks',
		'create:small_dripstone_brick_slab',
		'create:small_dripstone_brick_stairs',
		'create:small_dripstone_brick_wall',
		'create:small_dripstone_bricks',
		'create:small_granite_brick_slab',
		'create:small_granite_brick_stairs',
		'create:small_granite_brick_wall',
		'create:small_granite_bricks',
		'create:small_limestone_brick_slab',
		'create:small_limestone_brick_stairs',
		'create:small_limestone_brick_wall',
		'create:small_limestone_bricks',
		'create:small_ochrum_brick_slab',
		'create:small_ochrum_brick_stairs',
		'create:small_ochrum_brick_wall',
		'create:small_ochrum_bricks',
		'create:small_rose_quartz_tiles',
		'create:small_scorchia_brick_slab',
		'create:small_scorchia_brick_stairs',
		'create:small_scorchia_brick_wall',
		'create:small_scorchia_bricks',
		'create:small_scoria_brick_slab',
		'create:small_scoria_brick_stairs',
		'create:small_scoria_brick_wall',
		'create:small_scoria_bricks',
		'create:small_tuff_brick_slab',
		'create:small_tuff_brick_stairs',
		'create:small_tuff_brick_wall',
		'create:small_tuff_bricks',
		'create:small_veridium_brick_slab',
		'create:small_veridium_brick_stairs',
		'create:small_veridium_brick_wall',
		'create:small_veridium_bricks',
		'create:smart_chute',
		'create:smart_fluid_pipe',
		'create:speedometer',
		'create:spout',
		'create:spruce_window',
		'create:spruce_window_pane',
		'create:steam_engine',
		'create:steam_whistle',
		'create:steam_whistle_extension',
		'create:sticker',
		'create:sticky_mechanical_piston',
		'create:stockpile_switch',
		'create:stressometer',
		'create:tiled_glass',
		'create:tiled_glass_pane',
		'create:track',
		'create:track_observer',
		'create:track_signal',
		'create:track_station',
		'create:train_door',
		'create:train_trapdoor',
		'create:tuff_pillar',
		'create:turntable',
		'create:veridium',
		'create:veridium_pillar',
		'create:vertical_framed_glass',
		'create:vertical_framed_glass_pane',
		'create:warped_window',
		'create:warped_window_pane',
		'create:water_wheel',
		'create:water_wheel_structure',
		'create:waxed_copper_shingle_slab',
		'create:waxed_copper_shingle_stairs',
		'create:waxed_copper_shingles',
		'create:waxed_copper_tile_slab',
		'create:waxed_copper_tile_stairs',
		'create:waxed_copper_tiles',
		'create:waxed_exposed_copper_shingle_slab',
		'create:waxed_exposed_copper_shingle_stairs',
		'create:waxed_exposed_copper_shingles',
		'create:waxed_exposed_copper_tile_slab',
		'create:waxed_exposed_copper_tile_stairs',
		'create:waxed_exposed_copper_tiles',
		'create:waxed_oxidized_copper_shingle_slab',
		'create:waxed_oxidized_copper_shingle_stairs',
		'create:waxed_oxidized_copper_shingles',
		'create:waxed_oxidized_copper_tile_slab',
		'create:waxed_oxidized_copper_tile_stairs',
		'create:waxed_oxidized_copper_tiles',
		'create:waxed_weathered_copper_shingle_slab',
		'create:waxed_weathered_copper_shingle_stairs',
		'create:waxed_weathered_copper_shingles',
		'create:waxed_weathered_copper_tile_slab',
		'create:waxed_weathered_copper_tile_stairs',
		'create:waxed_weathered_copper_tiles',
		'create:weathered_copper_shingle_slab',
		'create:weathered_copper_shingle_stairs',
		'create:weathered_copper_shingles',
		'create:weathered_copper_tile_slab',
		'create:weathered_copper_tile_stairs',
		'create:weathered_copper_tiles',
		'create:weighted_ejector',
		'create:white_nixie_tube',
		'create:white_sail',
		'create:white_seat',
		'create:white_toolbox',
		'create:white_valve_handle',
		'create:windmill_bearing',
		'create:wooden_bracket',
		'create:yellow_nixie_tube',
		'create:yellow_sail',
		'create:yellow_seat',
		'create:yellow_toolbox',
		'create:yellow_valve_handle',
		'create:zinc_block',
		'create:zinc_ore',
		'minecraft:acacia_button',
		'minecraft:acacia_door',
		'minecraft:acacia_fence',
		'minecraft:acacia_fence_gate',
		'minecraft:acacia_hanging_sign',
		'minecraft:acacia_leaves',
		'minecraft:acacia_log',
		'minecraft:acacia_planks',
		'minecraft:acacia_pressure_plate',
		'minecraft:acacia_sapling',
		'minecraft:acacia_sign',
		'minecraft:acacia_slab',
		'minecraft:acacia_stairs',
		'minecraft:acacia_trapdoor',
		'minecraft:acacia_wall_hanging_sign',
		'minecraft:acacia_wall_sign',
		'minecraft:acacia_wood',
		'minecraft:activator_rail',
		'minecraft:air',
		'minecraft:allium',
		'minecraft:amethyst_block',
		'minecraft:amethyst_cluster',
		'minecraft:ancient_debris',
		'minecraft:andesite',
		'minecraft:andesite_slab',
		'minecraft:andesite_stairs',
		'minecraft:andesite_wall',
		'minecraft:anvil',
		'minecraft:attached_melon_stem',
		'minecraft:attached_pumpkin_stem',
		'minecraft:azalea',
		'minecraft:azalea_leaves',
		'minecraft:azure_bluet',
		'minecraft:bamboo',
		'minecraft:bamboo_block',
		'minecraft:bamboo_button',
		'minecraft:bamboo_door',
		'minecraft:bamboo_fence',
		'minecraft:bamboo_fence_gate',
		'minecraft:bamboo_hanging_sign',
		'minecraft:bamboo_mosaic',
		'minecraft:bamboo_mosaic_slab',
		'minecraft:bamboo_mosaic_stairs',
		'minecraft:bamboo_planks',
		'minecraft:bamboo_pressure_plate',
		'minecraft:bamboo_sapling',
		'minecraft:bamboo_sign',
		'minecraft:bamboo_slab',
		'minecraft:bamboo_stairs',
		'minecraft:bamboo_trapdoor',
		'minecraft:bamboo_wall_hanging_sign',
		'minecraft:bamboo_wall_sign',
		'minecraft:barrel',
		'minecraft:barrier',
		'minecraft:basalt',
		'minecraft:beacon',
		'minecraft:bedrock',
		'minecraft:bee_nest',
		'minecraft:beehive',
		'minecraft:beetroots',
		'minecraft:bell',
		'minecraft:big_dripleaf',
		'minecraft:big_dripleaf_stem',
		'minecraft:birch_button',
		'minecraft:birch_door',
		'minecraft:birch_fence',
		'minecraft:birch_fence_gate',
		'minecraft:birch_hanging_sign',
		'minecraft:birch_leaves',
		'minecraft:birch_log',
		'minecraft:birch_planks',
		'minecraft:birch_pressure_plate',
		'minecraft:birch_sapling',
		'minecraft:birch_sign',
		'minecraft:birch_slab',
		'minecraft:birch_stairs',
		'minecraft:birch_trapdoor',
		'minecraft:birch_wall_hanging_sign',
		'minecraft:birch_wall_sign',
		'minecraft:birch_wood',
		'minecraft:black_banner',
		'minecraft:black_bed',
		'minecraft:black_candle',
		'minecraft:black_candle_cake',
		'minecraft:black_carpet',
		'minecraft:black_concrete',
		'minecraft:black_concrete_powder',
		'minecraft:black_glazed_terracotta',
		'minecraft:black_shulker_box',
		'minecraft:black_stained_glass',
		'minecraft:black_stained_glass_pane',
		'minecraft:black_terracotta',
		'minecraft:black_wall_banner',
		'minecraft:black_wool',
		'minecraft:blackstone',
		'minecraft:blackstone_slab',
		'minecraft:blackstone_stairs',
		'minecraft:blackstone_wall',
		'minecraft:blast_furnace',
		'minecraft:blue_banner',
		'minecraft:blue_bed',
		'minecraft:blue_candle',
		'minecraft:blue_candle_cake',
		'minecraft:blue_carpet',
		'minecraft:blue_concrete',
		'minecraft:blue_concrete_powder',
		'minecraft:blue_glazed_terracotta',
		'minecraft:blue_ice',
		'minecraft:blue_orchid',
		'minecraft:blue_shulker_box',
		'minecraft:blue_stained_glass',
		'minecraft:blue_stained_glass_pane',
		'minecraft:blue_terracotta',
		'minecraft:blue_wall_banner',
		'minecraft:blue_wool',
		'minecraft:bone_block',
		'minecraft:bookshelf',
		'minecraft:brain_coral',
		'minecraft:brain_coral_block',
		'minecraft:brain_coral_fan',
		'minecraft:brain_coral_wall_fan',
		'minecraft:brewing_stand',
		'minecraft:brick_slab',
		'minecraft:brick_stairs',
		'minecraft:brick_wall',
		'minecraft:bricks',
		'minecraft:brown_banner',
		'minecraft:brown_bed',
		'minecraft:brown_candle',
		'minecraft:brown_candle_cake',
		'minecraft:brown_carpet',
		'minecraft:brown_concrete',
		'minecraft:brown_concrete_powder',
		'minecraft:brown_glazed_terracotta',
		'minecraft:brown_mushroom',
		'minecraft:brown_mushroom_block',
		'minecraft:brown_shulker_box',
		'minecraft:brown_stained_glass',
		'minecraft:brown_stained_glass_pane',
		'minecraft:brown_terracotta',
		'minecraft:brown_wall_banner',
		'minecraft:brown_wool',
		'minecraft:bubble_column',
		'minecraft:bubble_coral',
		'minecraft:bubble_coral_block',
		'minecraft:bubble_coral_fan',
		'minecraft:bubble_coral_wall_fan',
		'minecraft:budding_amethyst',
		'minecraft:cactus',
		'minecraft:cake',
		'minecraft:calcite',
		'minecraft:calibrated_sculk_sensor',
		'minecraft:campfire',
		'minecraft:candle',
		'minecraft:candle_cake',
		'minecraft:carrots',
		'minecraft:cartography_table',
		'minecraft:carved_pumpkin',
		'minecraft:cauldron',
		'minecraft:cave_air',
		'minecraft:cave_vines',
		'minecraft:cave_vines_plant',
		'minecraft:chain',
		'minecraft:chain_command_block',
		'minecraft:cherry_button',
		'minecraft:cherry_door',
		'minecraft:cherry_fence',
		'minecraft:cherry_fence_gate',
		'minecraft:cherry_hanging_sign',
		'minecraft:cherry_leaves',
		'minecraft:cherry_log',
		'minecraft:cherry_planks',
		'minecraft:cherry_pressure_plate',
		'minecraft:cherry_sapling',
		'minecraft:cherry_sign',
		'minecraft:cherry_slab',
		'minecraft:cherry_stairs',
		'minecraft:cherry_trapdoor',
		'minecraft:cherry_wall_hanging_sign',
		'minecraft:cherry_wall_sign',
		'minecraft:cherry_wood',
		'minecraft:chest',
		'minecraft:chipped_anvil',
		'minecraft:chiseled_bookshelf',
		'minecraft:chiseled_deepslate',
		'minecraft:chiseled_nether_bricks',
		'minecraft:chiseled_polished_blackstone',
		'minecraft:chiseled_quartz_block',
		'minecraft:chiseled_red_sandstone',
		'minecraft:chiseled_sandstone',
		'minecraft:chiseled_stone_bricks',
		'minecraft:chorus_flower',
		'minecraft:chorus_plant',
		'minecraft:clay',
		'minecraft:coal_block',
		'minecraft:coal_ore',
		'minecraft:coarse_dirt',
		'minecraft:cobbled_deepslate',
		'minecraft:cobbled_deepslate_slab',
		'minecraft:cobbled_deepslate_stairs',
		'minecraft:cobbled_deepslate_wall',
		'minecraft:cobblestone',
		'minecraft:cobblestone_slab',
		'minecraft:cobblestone_stairs',
		'minecraft:cobblestone_wall',
		'minecraft:cobweb',
		'minecraft:cocoa',
		'minecraft:command_block',
		'minecraft:comparator',
		'minecraft:composter',
		'minecraft:conduit',
		'minecraft:copper_block',
		'minecraft:copper_ore',
		'minecraft:cornflower',
		'minecraft:cracked_deepslate_bricks',
		'minecraft:cracked_deepslate_tiles',
		'minecraft:cracked_nether_bricks',
		'minecraft:cracked_polished_blackstone_bricks',
		'minecraft:cracked_stone_bricks',
		'minecraft:crafting_table',
		'minecraft:creeper_head',
		'minecraft:creeper_wall_head',
		'minecraft:crimson_button',
		'minecraft:crimson_door',
		'minecraft:crimson_fence',
		'minecraft:crimson_fence_gate',
		'minecraft:crimson_fungus',
		'minecraft:crimson_hanging_sign',
		'minecraft:crimson_hyphae',
		'minecraft:crimson_nylium',
		'minecraft:crimson_planks',
		'minecraft:crimson_pressure_plate',
		'minecraft:crimson_roots',
		'minecraft:crimson_sign',
		'minecraft:crimson_slab',
		'minecraft:crimson_stairs',
		'minecraft:crimson_stem',
		'minecraft:crimson_trapdoor',
		'minecraft:crimson_wall_hanging_sign',
		'minecraft:crimson_wall_sign',
		'minecraft:crying_obsidian',
		'minecraft:cut_copper',
		'minecraft:cut_copper_slab',
		'minecraft:cut_copper_stairs',
		'minecraft:cut_red_sandstone',
		'minecraft:cut_red_sandstone_slab',
		'minecraft:cut_sandstone',
		'minecraft:cut_sandstone_slab',
		'minecraft:cyan_banner',
		'minecraft:cyan_bed',
		'minecraft:cyan_candle',
		'minecraft:cyan_candle_cake',
		'minecraft:cyan_carpet',
		'minecraft:cyan_concrete',
		'minecraft:cyan_concrete_powder',
		'minecraft:cyan_glazed_terracotta',
		'minecraft:cyan_shulker_box',
		'minecraft:cyan_stained_glass',
		'minecraft:cyan_stained_glass_pane',
		'minecraft:cyan_terracotta',
		'minecraft:cyan_wall_banner',
		'minecraft:cyan_wool',
		'minecraft:damaged_anvil',
		'minecraft:dandelion',
		'minecraft:dark_oak_button',
		'minecraft:dark_oak_door',
		'minecraft:dark_oak_fence',
		'minecraft:dark_oak_fence_gate',
		'minecraft:dark_oak_hanging_sign',
		'minecraft:dark_oak_leaves',
		'minecraft:dark_oak_log',
		'minecraft:dark_oak_planks',
		'minecraft:dark_oak_pressure_plate',
		'minecraft:dark_oak_sapling',
		'minecraft:dark_oak_sign',
		'minecraft:dark_oak_slab',
		'minecraft:dark_oak_stairs',
		'minecraft:dark_oak_trapdoor',
		'minecraft:dark_oak_wall_hanging_sign',
		'minecraft:dark_oak_wall_sign',
		'minecraft:dark_oak_wood',
		'minecraft:dark_prismarine',
		'minecraft:dark_prismarine_slab',
		'minecraft:dark_prismarine_stairs',
		'minecraft:daylight_detector',
		'minecraft:dead_brain_coral',
		'minecraft:dead_brain_coral_block',
		'minecraft:dead_brain_coral_fan',
		'minecraft:dead_brain_coral_wall_fan',
		'minecraft:dead_bubble_coral',
		'minecraft:dead_bubble_coral_block',
		'minecraft:dead_bubble_coral_fan',
		'minecraft:dead_bubble_coral_wall_fan',
		'minecraft:dead_bush',
		'minecraft:dead_fire_coral',
		'minecraft:dead_fire_coral_block',
		'minecraft:dead_fire_coral_fan',
		'minecraft:dead_fire_coral_wall_fan',
		'minecraft:dead_horn_coral',
		'minecraft:dead_horn_coral_block',
		'minecraft:dead_horn_coral_fan',
		'minecraft:dead_horn_coral_wall_fan',
		'minecraft:dead_tube_coral',
		'minecraft:dead_tube_coral_block',
		'minecraft:dead_tube_coral_fan',
		'minecraft:dead_tube_coral_wall_fan',
		'minecraft:decorated_pot',
		'minecraft:deepslate',
		'minecraft:deepslate_brick_slab',
		'minecraft:deepslate_brick_stairs',
		'minecraft:deepslate_brick_wall',
		'minecraft:deepslate_bricks',
		'minecraft:deepslate_coal_ore',
		'minecraft:deepslate_copper_ore',
		'minecraft:deepslate_diamond_ore',
		'minecraft:deepslate_emerald_ore',
		'minecraft:deepslate_gold_ore',
		'minecraft:deepslate_iron_ore',
		'minecraft:deepslate_lapis_ore',
		'minecraft:deepslate_redstone_ore',
		'minecraft:deepslate_tile_slab',
		'minecraft:deepslate_tile_stairs',
		'minecraft:deepslate_tile_wall',
		'minecraft:deepslate_tiles',
		'minecraft:detector_rail',
		'minecraft:diamond_block',
		'minecraft:diamond_ore',
		'minecraft:diorite',
		'minecraft:diorite_slab',
		'minecraft:diorite_stairs',
		'minecraft:diorite_wall',
		'minecraft:dirt',
		'minecraft:dirt_path',
		'minecraft:dispenser',
		'minecraft:dragon_egg',
		'minecraft:dragon_head',
		'minecraft:dragon_wall_head',
		'minecraft:dried_kelp_block',
		'minecraft:dripstone_block',
		'minecraft:dropper',
		'minecraft:emerald_block',
		'minecraft:emerald_ore',
		'minecraft:enchanting_table',
		'minecraft:end_gateway',
		'minecraft:end_portal',
		'minecraft:end_portal_frame',
		'minecraft:end_rod',
		'minecraft:end_stone',
		'minecraft:end_stone_brick_slab',
		'minecraft:end_stone_brick_stairs',
		'minecraft:end_stone_brick_wall',
		'minecraft:end_stone_bricks',
		'minecraft:ender_chest',
		'minecraft:exposed_copper',
		'minecraft:exposed_cut_copper',
		'minecraft:exposed_cut_copper_slab',
		'minecraft:exposed_cut_copper_stairs',
		'minecraft:farmland',
		'minecraft:fern',
		'minecraft:fire',
		'minecraft:fire_coral',
		'minecraft:fire_coral_block',
		'minecraft:fire_coral_fan',
		'minecraft:fire_coral_wall_fan',
		'minecraft:fletching_table',
		'minecraft:flower_pot',
		'minecraft:flowering_azalea',
		'minecraft:flowering_azalea_leaves',
		'minecraft:frogspawn',
		'minecraft:frosted_ice',
		'minecraft:furnace',
		'minecraft:gilded_blackstone',
		'minecraft:glass',
		'minecraft:glass_pane',
		'minecraft:glow_lichen',
		'minecraft:glowstone',
		'minecraft:gold_block',
		'minecraft:gold_ore',
		'minecraft:granite',
		'minecraft:granite_slab',
		'minecraft:granite_stairs',
		'minecraft:granite_wall',
		'minecraft:grass',
		'minecraft:grass_block',
		'minecraft:gravel',
		'minecraft:gray_banner',
		'minecraft:gray_bed',
		'minecraft:gray_candle',
		'minecraft:gray_candle_cake',
		'minecraft:gray_carpet',
		'minecraft:gray_concrete',
		'minecraft:gray_concrete_powder',
		'minecraft:gray_glazed_terracotta',
		'minecraft:gray_shulker_box',
		'minecraft:gray_stained_glass',
		'minecraft:gray_stained_glass_pane',
		'minecraft:gray_terracotta',
		'minecraft:gray_wall_banner',
		'minecraft:gray_wool',
		'minecraft:green_banner',
		'minecraft:green_bed',
		'minecraft:green_candle',
		'minecraft:green_candle_cake',
		'minecraft:green_carpet',
		'minecraft:green_concrete',
		'minecraft:green_concrete_powder',
		'minecraft:green_glazed_terracotta',
		'minecraft:green_shulker_box',
		'minecraft:green_stained_glass',
		'minecraft:green_stained_glass_pane',
		'minecraft:green_terracotta',
		'minecraft:green_wall_banner',
		'minecraft:green_wool',
		'minecraft:grindstone',
		'minecraft:hanging_roots',
		'minecraft:hay_block',
		'minecraft:heavy_weighted_pressure_plate',
		'minecraft:honey_block',
		'minecraft:honeycomb_block',
		'minecraft:hopper',
		'minecraft:horn_coral',
		'minecraft:horn_coral_block',
		'minecraft:horn_coral_fan',
		'minecraft:horn_coral_wall_fan',
		'minecraft:ice',
		'minecraft:infested_chiseled_stone_bricks',
		'minecraft:infested_cobblestone',
		'minecraft:infested_cracked_stone_bricks',
		'minecraft:infested_deepslate',
		'minecraft:infested_mossy_stone_bricks',
		'minecraft:infested_stone',
		'minecraft:infested_stone_bricks',
		'minecraft:iron_bars',
		'minecraft:iron_block',
		'minecraft:iron_door',
		'minecraft:iron_ore',
		'minecraft:iron_trapdoor',
		'minecraft:jack_o_lantern',
		'minecraft:jigsaw',
		'minecraft:jukebox',
		'minecraft:jungle_button',
		'minecraft:jungle_door',
		'minecraft:jungle_fence',
		'minecraft:jungle_fence_gate',
		'minecraft:jungle_hanging_sign',
		'minecraft:jungle_leaves',
		'minecraft:jungle_log',
		'minecraft:jungle_planks',
		'minecraft:jungle_pressure_plate',
		'minecraft:jungle_sapling',
		'minecraft:jungle_sign',
		'minecraft:jungle_slab',
		'minecraft:jungle_stairs',
		'minecraft:jungle_trapdoor',
		'minecraft:jungle_wall_hanging_sign',
		'minecraft:jungle_wall_sign',
		'minecraft:jungle_wood',
		'minecraft:kelp',
		'minecraft:kelp_plant',
		'minecraft:ladder',
		'minecraft:lantern',
		'minecraft:lapis_block',
		'minecraft:lapis_ore',
		'minecraft:large_amethyst_bud',
		'minecraft:large_fern',
		'minecraft:lava',
		'minecraft:lava_cauldron',
		'minecraft:lectern',
		'minecraft:lever',
		'minecraft:light',
		'minecraft:light_blue_banner',
		'minecraft:light_blue_bed',
		'minecraft:light_blue_candle',
		'minecraft:light_blue_candle_cake',
		'minecraft:light_blue_carpet',
		'minecraft:light_blue_concrete',
		'minecraft:light_blue_concrete_powder',
		'minecraft:light_blue_glazed_terracotta',
		'minecraft:light_blue_shulker_box',
		'minecraft:light_blue_stained_glass',
		'minecraft:light_blue_stained_glass_pane',
		'minecraft:light_blue_terracotta',
		'minecraft:light_blue_wall_banner',
		'minecraft:light_blue_wool',
		'minecraft:light_gray_banner',
		'minecraft:light_gray_bed',
		'minecraft:light_gray_candle',
		'minecraft:light_gray_candle_cake',
		'minecraft:light_gray_carpet',
		'minecraft:light_gray_concrete',
		'minecraft:light_gray_concrete_powder',
		'minecraft:light_gray_glazed_terracotta',
		'minecraft:light_gray_shulker_box',
		'minecraft:light_gray_stained_glass',
		'minecraft:light_gray_stained_glass_pane',
		'minecraft:light_gray_terracotta',
		'minecraft:light_gray_wall_banner',
		'minecraft:light_gray_wool',
		'minecraft:light_weighted_pressure_plate',
		'minecraft:lightning_rod',
		'minecraft:lilac',
		'minecraft:lily_of_the_valley',
		'minecraft:lily_pad',
		'minecraft:lime_banner',
		'minecraft:lime_bed',
		'minecraft:lime_candle',
		'minecraft:lime_candle_cake',
		'minecraft:lime_carpet',
		'minecraft:lime_concrete',
		'minecraft:lime_concrete_powder',
		'minecraft:lime_glazed_terracotta',
		'minecraft:lime_shulker_box',
		'minecraft:lime_stained_glass',
		'minecraft:lime_stained_glass_pane',
		'minecraft:lime_terracotta',
		'minecraft:lime_wall_banner',
		'minecraft:lime_wool',
		'minecraft:lodestone',
		'minecraft:loom',
		'minecraft:magenta_banner',
		'minecraft:magenta_bed',
		'minecraft:magenta_candle',
		'minecraft:magenta_candle_cake',
		'minecraft:magenta_carpet',
		'minecraft:magenta_concrete',
		'minecraft:magenta_concrete_powder',
		'minecraft:magenta_glazed_terracotta',
		'minecraft:magenta_shulker_box',
		'minecraft:magenta_stained_glass',
		'minecraft:magenta_stained_glass_pane',
		'minecraft:magenta_terracotta',
		'minecraft:magenta_wall_banner',
		'minecraft:magenta_wool',
		'minecraft:magma_block',
		'minecraft:mangrove_button',
		'minecraft:mangrove_door',
		'minecraft:mangrove_fence',
		'minecraft:mangrove_fence_gate',
		'minecraft:mangrove_hanging_sign',
		'minecraft:mangrove_leaves',
		'minecraft:mangrove_log',
		'minecraft:mangrove_planks',
		'minecraft:mangrove_pressure_plate',
		'minecraft:mangrove_propagule',
		'minecraft:mangrove_roots',
		'minecraft:mangrove_sign',
		'minecraft:mangrove_slab',
		'minecraft:mangrove_stairs',
		'minecraft:mangrove_trapdoor',
		'minecraft:mangrove_wall_hanging_sign',
		'minecraft:mangrove_wall_sign',
		'minecraft:mangrove_wood',
		'minecraft:medium_amethyst_bud',
		'minecraft:melon',
		'minecraft:melon_stem',
		'minecraft:moss_block',
		'minecraft:moss_carpet',
		'minecraft:mossy_cobblestone',
		'minecraft:mossy_cobblestone_slab',
		'minecraft:mossy_cobblestone_stairs',
		'minecraft:mossy_cobblestone_wall',
		'minecraft:mossy_stone_brick_slab',
		'minecraft:mossy_stone_brick_stairs',
		'minecraft:mossy_stone_brick_wall',
		'minecraft:mossy_stone_bricks',
		'minecraft:moving_piston',
		'minecraft:mud',
		'minecraft:mud_brick_slab',
		'minecraft:mud_brick_stairs',
		'minecraft:mud_brick_wall',
		'minecraft:mud_bricks',
		'minecraft:muddy_mangrove_roots',
		'minecraft:mushroom_stem',
		'minecraft:mycelium',
		'minecraft:nether_brick_fence',
		'minecraft:nether_brick_slab',
		'minecraft:nether_brick_stairs',
		'minecraft:nether_brick_wall',
		'minecraft:nether_bricks',
		'minecraft:nether_gold_ore',
		'minecraft:nether_portal',
		'minecraft:nether_quartz_ore',
		'minecraft:nether_sprouts',
		'minecraft:nether_wart',
		'minecraft:nether_wart_block',
		'minecraft:netherite_block',
		'minecraft:netherrack',
		'minecraft:note_block',
		'minecraft:oak_button',
		'minecraft:oak_door',
		'minecraft:oak_fence',
		'minecraft:oak_fence_gate',
		'minecraft:oak_hanging_sign',
		'minecraft:oak_leaves',
		'minecraft:oak_log',
		'minecraft:oak_planks',
		'minecraft:oak_pressure_plate',
		'minecraft:oak_sapling',
		'minecraft:oak_sign',
		'minecraft:oak_slab',
		'minecraft:oak_stairs',
		'minecraft:oak_trapdoor',
		'minecraft:oak_wall_hanging_sign',
		'minecraft:oak_wall_sign',
		'minecraft:oak_wood',
		'minecraft:observer',
		'minecraft:obsidian',
		'minecraft:ochre_froglight',
		'minecraft:orange_banner',
		'minecraft:orange_bed',
		'minecraft:orange_candle',
		'minecraft:orange_candle_cake',
		'minecraft:orange_carpet',
		'minecraft:orange_concrete',
		'minecraft:orange_concrete_powder',
		'minecraft:orange_glazed_terracotta',
		'minecraft:orange_shulker_box',
		'minecraft:orange_stained_glass',
		'minecraft:orange_stained_glass_pane',
		'minecraft:orange_terracotta',
		'minecraft:orange_tulip',
		'minecraft:orange_wall_banner',
		'minecraft:orange_wool',
		'minecraft:oxeye_daisy',
		'minecraft:oxidized_copper',
		'minecraft:oxidized_cut_copper',
		'minecraft:oxidized_cut_copper_slab',
		'minecraft:oxidized_cut_copper_stairs',
		'minecraft:packed_ice',
		'minecraft:packed_mud',
		'minecraft:pearlescent_froglight',
		'minecraft:peony',
		'minecraft:petrified_oak_slab',
		'minecraft:piglin_head',
		'minecraft:piglin_wall_head',
		'minecraft:pink_banner',
		'minecraft:pink_bed',
		'minecraft:pink_candle',
		'minecraft:pink_candle_cake',
		'minecraft:pink_carpet',
		'minecraft:pink_concrete',
		'minecraft:pink_concrete_powder',
		'minecraft:pink_glazed_terracotta',
		'minecraft:pink_petals',
		'minecraft:pink_shulker_box',
		'minecraft:pink_stained_glass',
		'minecraft:pink_stained_glass_pane',
		'minecraft:pink_terracotta',
		'minecraft:pink_tulip',
		'minecraft:pink_wall_banner',
		'minecraft:pink_wool',
		'minecraft:piston',
		'minecraft:piston_head',
		'minecraft:pitcher_crop',
		'minecraft:pitcher_plant',
		'minecraft:player_head',
		'minecraft:player_wall_head',
		'minecraft:podzol',
		'minecraft:pointed_dripstone',
		'minecraft:polished_andesite',
		'minecraft:polished_andesite_slab',
		'minecraft:polished_andesite_stairs',
		'minecraft:polished_basalt',
		'minecraft:polished_blackstone',
		'minecraft:polished_blackstone_brick_slab',
		'minecraft:polished_blackstone_brick_stairs',
		'minecraft:polished_blackstone_brick_wall',
		'minecraft:polished_blackstone_bricks',
		'minecraft:polished_blackstone_button',
		'minecraft:polished_blackstone_pressure_plate',
		'minecraft:polished_blackstone_slab',
		'minecraft:polished_blackstone_stairs',
		'minecraft:polished_blackstone_wall',
		'minecraft:polished_deepslate',
		'minecraft:polished_deepslate_slab',
		'minecraft:polished_deepslate_stairs',
		'minecraft:polished_deepslate_wall',
		'minecraft:polished_diorite',
		'minecraft:polished_diorite_slab',
		'minecraft:polished_diorite_stairs',
		'minecraft:polished_granite',
		'minecraft:polished_granite_slab',
		'minecraft:polished_granite_stairs',
		'minecraft:poppy',
		'minecraft:potatoes',
		'minecraft:potted_acacia_sapling',
		'minecraft:potted_allium',
		'minecraft:potted_azalea_bush',
		'minecraft:potted_azure_bluet',
		'minecraft:potted_bamboo',
		'minecraft:potted_birch_sapling',
		'minecraft:potted_blue_orchid',
		'minecraft:potted_brown_mushroom',
		'minecraft:potted_cactus',
		'minecraft:potted_cherry_sapling',
		'minecraft:potted_cornflower',
		'minecraft:potted_crimson_fungus',
		'minecraft:potted_crimson_roots',
		'minecraft:potted_dandelion',
		'minecraft:potted_dark_oak_sapling',
		'minecraft:potted_dead_bush',
		'minecraft:potted_fern',
		'minecraft:potted_flowering_azalea_bush',
		'minecraft:potted_jungle_sapling',
		'minecraft:potted_lily_of_the_valley',
		'minecraft:potted_mangrove_propagule',
		'minecraft:potted_oak_sapling',
		'minecraft:potted_orange_tulip',
		'minecraft:potted_oxeye_daisy',
		'minecraft:potted_pink_tulip',
		'minecraft:potted_poppy',
		'minecraft:potted_red_mushroom',
		'minecraft:potted_red_tulip',
		'minecraft:potted_spruce_sapling',
		'minecraft:potted_torchflower',
		'minecraft:potted_warped_fungus',
		'minecraft:potted_warped_roots',
		'minecraft:potted_white_tulip',
		'minecraft:potted_wither_rose',
		'minecraft:powder_snow',
		'minecraft:powder_snow_cauldron',
		'minecraft:powered_rail',
		'minecraft:prismarine',
		'minecraft:prismarine_brick_slab',
		'minecraft:prismarine_brick_stairs',
		'minecraft:prismarine_bricks',
		'minecraft:prismarine_slab',
		'minecraft:prismarine_stairs',
		'minecraft:prismarine_wall',
		'minecraft:pumpkin',
		'minecraft:pumpkin_stem',
		'minecraft:purple_banner',
		'minecraft:purple_bed',
		'minecraft:purple_candle',
		'minecraft:purple_candle_cake',
		'minecraft:purple_carpet',
		'minecraft:purple_concrete',
		'minecraft:purple_concrete_powder',
		'minecraft:purple_glazed_terracotta',
		'minecraft:purple_shulker_box',
		'minecraft:purple_stained_glass',
		'minecraft:purple_stained_glass_pane',
		'minecraft:purple_terracotta',
		'minecraft:purple_wall_banner',
		'minecraft:purple_wool',
		'minecraft:purpur_block',
		'minecraft:purpur_pillar',
		'minecraft:purpur_slab',
		'minecraft:purpur_stairs',
		'minecraft:quartz_block',
		'minecraft:quartz_bricks',
		'minecraft:quartz_pillar',
		'minecraft:quartz_slab',
		'minecraft:quartz_stairs',
		'minecraft:rail',
		'minecraft:raw_copper_block',
		'minecraft:raw_gold_block',
		'minecraft:raw_iron_block',
		'minecraft:red_banner',
		'minecraft:red_bed',
		'minecraft:red_candle',
		'minecraft:red_candle_cake',
		'minecraft:red_carpet',
		'minecraft:red_concrete',
		'minecraft:red_concrete_powder',
		'minecraft:red_glazed_terracotta',
		'minecraft:red_mushroom',
		'minecraft:red_mushroom_block',
		'minecraft:red_nether_brick_slab',
		'minecraft:red_nether_brick_stairs',
		'minecraft:red_nether_brick_wall',
		'minecraft:red_nether_bricks',
		'minecraft:red_sand',
		'minecraft:red_sandstone',
		'minecraft:red_sandstone_slab',
		'minecraft:red_sandstone_stairs',
		'minecraft:red_sandstone_wall',
		'minecraft:red_shulker_box',
		'minecraft:red_stained_glass',
		'minecraft:red_stained_glass_pane',
		'minecraft:red_terracotta',
		'minecraft:red_tulip',
		'minecraft:red_wall_banner',
		'minecraft:red_wool',
		'minecraft:redstone_block',
		'minecraft:redstone_lamp',
		'minecraft:redstone_ore',
		'minecraft:redstone_torch',
		'minecraft:redstone_wall_torch',
		'minecraft:redstone_wire',
		'minecraft:reinforced_deepslate',
		'minecraft:repeater',
		'minecraft:repeating_command_block',
		'minecraft:respawn_anchor',
		'minecraft:rooted_dirt',
		'minecraft:rose_bush',
		'minecraft:sand',
		'minecraft:sandstone',
		'minecraft:sandstone_slab',
		'minecraft:sandstone_stairs',
		'minecraft:sandstone_wall',
		'minecraft:scaffolding',
		'minecraft:sculk',
		'minecraft:sculk_catalyst',
		'minecraft:sculk_sensor',
		'minecraft:sculk_shrieker',
		'minecraft:sculk_vein',
		'minecraft:sea_lantern',
		'minecraft:sea_pickle',
		'minecraft:seagrass',
		'minecraft:shroomlight',
		'minecraft:shulker_box',
		'minecraft:skeleton_skull',
		'minecraft:skeleton_wall_skull',
		'minecraft:slime_block',
		'minecraft:small_amethyst_bud',
		'minecraft:small_dripleaf',
		'minecraft:smithing_table',
		'minecraft:smoker',
		'minecraft:smooth_basalt',
		'minecraft:smooth_quartz',
		'minecraft:smooth_quartz_slab',
		'minecraft:smooth_quartz_stairs',
		'minecraft:smooth_red_sandstone',
		'minecraft:smooth_red_sandstone_slab',
		'minecraft:smooth_red_sandstone_stairs',
		'minecraft:smooth_sandstone',
		'minecraft:smooth_sandstone_slab',
		'minecraft:smooth_sandstone_stairs',
		'minecraft:smooth_stone',
		'minecraft:smooth_stone_slab',
		'minecraft:sniffer_egg',
		'minecraft:snow',
		'minecraft:snow_block',
		'minecraft:soul_campfire',
		'minecraft:soul_fire',
		'minecraft:soul_lantern',
		'minecraft:soul_sand',
		'minecraft:soul_soil',
		'minecraft:soul_torch',
		'minecraft:soul_wall_torch',
		'minecraft:spawner',
		'minecraft:sponge',
		'minecraft:spore_blossom',
		'minecraft:spruce_button',
		'minecraft:spruce_door',
		'minecraft:spruce_fence',
		'minecraft:spruce_fence_gate',
		'minecraft:spruce_hanging_sign',
		'minecraft:spruce_leaves',
		'minecraft:spruce_log',
		'minecraft:spruce_planks',
		'minecraft:spruce_pressure_plate',
		'minecraft:spruce_sapling',
		'minecraft:spruce_sign',
		'minecraft:spruce_slab',
		'minecraft:spruce_stairs',
		'minecraft:spruce_trapdoor',
		'minecraft:spruce_wall_hanging_sign',
		'minecraft:spruce_wall_sign',
		'minecraft:spruce_wood',
		'minecraft:sticky_piston',
		'minecraft:stone',
		'minecraft:stone_brick_slab',
		'minecraft:stone_brick_stairs',
		'minecraft:stone_brick_wall',
		'minecraft:stone_bricks',
		'minecraft:stone_button',
		'minecraft:stone_pressure_plate',
		'minecraft:stone_slab',
		'minecraft:stone_stairs',
		'minecraft:stonecutter',
		'minecraft:stripped_acacia_log',
		'minecraft:stripped_acacia_wood',
		'minecraft:stripped_bamboo_block',
		'minecraft:stripped_birch_log',
		'minecraft:stripped_birch_wood',
		'minecraft:stripped_cherry_log',
		'minecraft:stripped_cherry_wood',
		'minecraft:stripped_crimson_hyphae',
		'minecraft:stripped_crimson_stem',
		'minecraft:stripped_dark_oak_log',
		'minecraft:stripped_dark_oak_wood',
		'minecraft:stripped_jungle_log',
		'minecraft:stripped_jungle_wood',
		'minecraft:stripped_mangrove_log',
		'minecraft:stripped_mangrove_wood',
		'minecraft:stripped_oak_log',
		'minecraft:stripped_oak_wood',
		'minecraft:stripped_spruce_log',
		'minecraft:stripped_spruce_wood',
		'minecraft:stripped_warped_hyphae',
		'minecraft:stripped_warped_stem',
		'minecraft:structure_block',
		'minecraft:structure_void',
		'minecraft:sugar_cane',
		'minecraft:sunflower',
		'minecraft:suspicious_gravel',
		'minecraft:suspicious_sand',
		'minecraft:sweet_berry_bush',
		'minecraft:tall_grass',
		'minecraft:tall_seagrass',
		'minecraft:target',
		'minecraft:terracotta',
		'minecraft:tinted_glass',
		'minecraft:tnt',
		'minecraft:torch',
		'minecraft:torchflower',
		'minecraft:torchflower_crop',
		'minecraft:trapped_chest',
		'minecraft:tripwire',
		'minecraft:tripwire_hook',
		'minecraft:tube_coral',
		'minecraft:tube_coral_block',
		'minecraft:tube_coral_fan',
		'minecraft:tube_coral_wall_fan',
		'minecraft:tuff',
		'minecraft:turtle_egg',
		'minecraft:twisting_vines',
		'minecraft:twisting_vines_plant',
		'minecraft:verdant_froglight',
		'minecraft:vine',
		'minecraft:void_air',
		'minecraft:wall_torch',
		'minecraft:warped_button',
		'minecraft:warped_door',
		'minecraft:warped_fence',
		'minecraft:warped_fence_gate',
		'minecraft:warped_fungus',
		'minecraft:warped_hanging_sign',
		'minecraft:warped_hyphae',
		'minecraft:warped_nylium',
		'minecraft:warped_planks',
		'minecraft:warped_pressure_plate',
		'minecraft:warped_roots',
		'minecraft:warped_sign',
		'minecraft:warped_slab',
		'minecraft:warped_stairs',
		'minecraft:warped_stem',
		'minecraft:warped_trapdoor',
		'minecraft:warped_wall_hanging_sign',
		'minecraft:warped_wall_sign',
		'minecraft:warped_wart_block',
		'minecraft:water',
		'minecraft:water_cauldron',
		'minecraft:waxed_copper_block',
		'minecraft:waxed_cut_copper',
		'minecraft:waxed_cut_copper_slab',
		'minecraft:waxed_cut_copper_stairs',
		'minecraft:waxed_exposed_copper',
		'minecraft:waxed_exposed_cut_copper',
		'minecraft:waxed_exposed_cut_copper_slab',
		'minecraft:waxed_exposed_cut_copper_stairs',
		'minecraft:waxed_oxidized_copper',
		'minecraft:waxed_oxidized_cut_copper',
		'minecraft:waxed_oxidized_cut_copper_slab',
		'minecraft:waxed_oxidized_cut_copper_stairs',
		'minecraft:waxed_weathered_copper',
		'minecraft:waxed_weathered_cut_copper',
		'minecraft:waxed_weathered_cut_copper_slab',
		'minecraft:waxed_weathered_cut_copper_stairs',
		'minecraft:weathered_copper',
		'minecraft:weathered_cut_copper',
		'minecraft:weathered_cut_copper_slab',
		'minecraft:weathered_cut_copper_stairs',
		'minecraft:weeping_vines',
		'minecraft:weeping_vines_plant',
		'minecraft:wet_sponge',
		'minecraft:wheat',
		'minecraft:white_banner',
		'minecraft:white_bed',
		'minecraft:white_candle',
		'minecraft:white_candle_cake',
		'minecraft:white_carpet',
		'minecraft:white_concrete',
		'minecraft:white_concrete_powder',
		'minecraft:white_glazed_terracotta',
		'minecraft:white_shulker_box',
		'minecraft:white_stained_glass',
		'minecraft:white_stained_glass_pane',
		'minecraft:white_terracotta',
		'minecraft:white_tulip',
		'minecraft:white_wall_banner',
		'minecraft:white_wool',
		'minecraft:wither_rose',
		'minecraft:wither_skeleton_skull',
		'minecraft:wither_skeleton_wall_skull',
		'minecraft:yellow_banner',
		'minecraft:yellow_bed',
		'minecraft:yellow_candle',
		'minecraft:yellow_candle_cake',
		'minecraft:yellow_carpet',
		'minecraft:yellow_concrete',
		'minecraft:yellow_concrete_powder',
		'minecraft:yellow_glazed_terracotta',
		'minecraft:yellow_shulker_box',
		'minecraft:yellow_stained_glass',
		'minecraft:yellow_stained_glass_pane',
		'minecraft:yellow_terracotta',
		'minecraft:yellow_wall_banner',
		'minecraft:yellow_wool',
		'minecraft:zombie_head',
		'minecraft:zombie_wall_head',
		'more_slabs_stairs_and_walls:acacia_leaves_slab',
		'more_slabs_stairs_and_walls:acacia_leaves_stairs',
		'more_slabs_stairs_and_walls:acacia_leaves_wall',
		'more_slabs_stairs_and_walls:acacia_log_slab',
		'more_slabs_stairs_and_walls:acacia_log_stairs',
		'more_slabs_stairs_and_walls:acacia_log_wall',
		'more_slabs_stairs_and_walls:acacia_planks_wall',
		'more_slabs_stairs_and_walls:acacia_wood_slab',
		'more_slabs_stairs_and_walls:acacia_wood_stairs',
		'more_slabs_stairs_and_walls:acacia_wood_wall',
		'more_slabs_stairs_and_walls:amethyst_block_slab',
		'more_slabs_stairs_and_walls:amethyst_block_stairs',
		'more_slabs_stairs_and_walls:amethyst_block_wall',
		'more_slabs_stairs_and_walls:azalea_leaves_slab',
		'more_slabs_stairs_and_walls:azalea_leaves_stairs',
		'more_slabs_stairs_and_walls:azalea_leaves_wall',
		'more_slabs_stairs_and_walls:bamboo_block_slab',
		'more_slabs_stairs_and_walls:bamboo_block_stairs',
		'more_slabs_stairs_and_walls:bamboo_block_wall',
		'more_slabs_stairs_and_walls:bamboo_mosaic_wall',
		'more_slabs_stairs_and_walls:bamboo_planks_wall',
		'more_slabs_stairs_and_walls:basalt_slab',
		'more_slabs_stairs_and_walls:basalt_stairs',
		'more_slabs_stairs_and_walls:basalt_wall',
		'more_slabs_stairs_and_walls:bedrock_slab',
		'more_slabs_stairs_and_walls:bedrock_stairs',
		'more_slabs_stairs_and_walls:bedrock_wall',
		'more_slabs_stairs_and_walls:birch_leaves_slab',
		'more_slabs_stairs_and_walls:birch_leaves_stairs',
		'more_slabs_stairs_and_walls:birch_leaves_wall',
		'more_slabs_stairs_and_walls:birch_log_slab',
		'more_slabs_stairs_and_walls:birch_log_stairs',
		'more_slabs_stairs_and_walls:birch_log_wall',
		'more_slabs_stairs_and_walls:birch_planks_wall',
		'more_slabs_stairs_and_walls:birch_wood_slab',
		'more_slabs_stairs_and_walls:birch_wood_stairs',
		'more_slabs_stairs_and_walls:birch_wood_wall',
		'more_slabs_stairs_and_walls:black_concrete_powder_slab',
		'more_slabs_stairs_and_walls:black_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:black_concrete_powder_wall',
		'more_slabs_stairs_and_walls:black_concrete_slab',
		'more_slabs_stairs_and_walls:black_concrete_stairs',
		'more_slabs_stairs_and_walls:black_concrete_wall',
		'more_slabs_stairs_and_walls:black_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:black_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:black_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:black_stained_glass_slab',
		'more_slabs_stairs_and_walls:black_stained_glass_stairs',
		'more_slabs_stairs_and_walls:black_stained_glass_wall',
		'more_slabs_stairs_and_walls:black_terracotta_slab',
		'more_slabs_stairs_and_walls:black_terracotta_stairs',
		'more_slabs_stairs_and_walls:black_terracotta_wall',
		'more_slabs_stairs_and_walls:black_wool_slab',
		'more_slabs_stairs_and_walls:black_wool_stairs',
		'more_slabs_stairs_and_walls:black_wool_wall',
		'more_slabs_stairs_and_walls:blue_concrete_powder_slab',
		'more_slabs_stairs_and_walls:blue_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:blue_concrete_powder_wall',
		'more_slabs_stairs_and_walls:blue_concrete_slab',
		'more_slabs_stairs_and_walls:blue_concrete_stairs',
		'more_slabs_stairs_and_walls:blue_concrete_wall',
		'more_slabs_stairs_and_walls:blue_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:blue_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:blue_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:blue_ice_slab',
		'more_slabs_stairs_and_walls:blue_ice_stairs',
		'more_slabs_stairs_and_walls:blue_ice_wall',
		'more_slabs_stairs_and_walls:blue_stained_glass_slab',
		'more_slabs_stairs_and_walls:blue_stained_glass_stairs',
		'more_slabs_stairs_and_walls:blue_stained_glass_wall',
		'more_slabs_stairs_and_walls:blue_terracotta_slab',
		'more_slabs_stairs_and_walls:blue_terracotta_stairs',
		'more_slabs_stairs_and_walls:blue_terracotta_wall',
		'more_slabs_stairs_and_walls:blue_wool_slab',
		'more_slabs_stairs_and_walls:blue_wool_stairs',
		'more_slabs_stairs_and_walls:blue_wool_wall',
		'more_slabs_stairs_and_walls:bone_block_slab',
		'more_slabs_stairs_and_walls:bone_block_stairs',
		'more_slabs_stairs_and_walls:bone_block_wall',
		'more_slabs_stairs_and_walls:bookshelf_slab',
		'more_slabs_stairs_and_walls:bookshelf_stairs',
		'more_slabs_stairs_and_walls:bookshelf_wall',
		'more_slabs_stairs_and_walls:brain_coral_block_slab',
		'more_slabs_stairs_and_walls:brain_coral_block_stairs',
		'more_slabs_stairs_and_walls:brain_coral_block_wall',
		'more_slabs_stairs_and_walls:brown_concrete_powder_slab',
		'more_slabs_stairs_and_walls:brown_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:brown_concrete_powder_wall',
		'more_slabs_stairs_and_walls:brown_concrete_slab',
		'more_slabs_stairs_and_walls:brown_concrete_stairs',
		'more_slabs_stairs_and_walls:brown_concrete_wall',
		'more_slabs_stairs_and_walls:brown_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:brown_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:brown_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:brown_mushroom_block_slab',
		'more_slabs_stairs_and_walls:brown_mushroom_block_stairs',
		'more_slabs_stairs_and_walls:brown_mushroom_block_wall',
		'more_slabs_stairs_and_walls:brown_stained_glass_slab',
		'more_slabs_stairs_and_walls:brown_stained_glass_stairs',
		'more_slabs_stairs_and_walls:brown_stained_glass_wall',
		'more_slabs_stairs_and_walls:brown_terracotta_slab',
		'more_slabs_stairs_and_walls:brown_terracotta_stairs',
		'more_slabs_stairs_and_walls:brown_terracotta_wall',
		'more_slabs_stairs_and_walls:brown_wool_slab',
		'more_slabs_stairs_and_walls:brown_wool_stairs',
		'more_slabs_stairs_and_walls:brown_wool_wall',
		'more_slabs_stairs_and_walls:bubble_coral_block_slab',
		'more_slabs_stairs_and_walls:bubble_coral_block_stairs',
		'more_slabs_stairs_and_walls:bubble_coral_block_wall',
		'more_slabs_stairs_and_walls:calcite_slab',
		'more_slabs_stairs_and_walls:calcite_stairs',
		'more_slabs_stairs_and_walls:calcite_wall',
		'more_slabs_stairs_and_walls:cherry_leaves_slab',
		'more_slabs_stairs_and_walls:cherry_leaves_stairs',
		'more_slabs_stairs_and_walls:cherry_leaves_wall',
		'more_slabs_stairs_and_walls:cherry_log_slab',
		'more_slabs_stairs_and_walls:cherry_log_stairs',
		'more_slabs_stairs_and_walls:cherry_log_wall',
		'more_slabs_stairs_and_walls:cherry_planks_wall',
		'more_slabs_stairs_and_walls:cherry_wood_slab',
		'more_slabs_stairs_and_walls:cherry_wood_stairs',
		'more_slabs_stairs_and_walls:cherry_wood_wall',
		'more_slabs_stairs_and_walls:chiseled_deepslate_slab',
		'more_slabs_stairs_and_walls:chiseled_deepslate_stairs',
		'more_slabs_stairs_and_walls:chiseled_deepslate_wall',
		'more_slabs_stairs_and_walls:chiseled_nether_bricks_slab',
		'more_slabs_stairs_and_walls:chiseled_nether_bricks_stairs',
		'more_slabs_stairs_and_walls:chiseled_nether_bricks_wall',
		'more_slabs_stairs_and_walls:chiseled_polished_blackstone_slab',
		'more_slabs_stairs_and_walls:chiseled_polished_blackstone_stairs',
		'more_slabs_stairs_and_walls:chiseled_polished_blackstone_wall',
		'more_slabs_stairs_and_walls:chiseled_quartz_block_slab',
		'more_slabs_stairs_and_walls:chiseled_quartz_block_stairs',
		'more_slabs_stairs_and_walls:chiseled_quartz_block_wall',
		'more_slabs_stairs_and_walls:chiseled_red_sandstone_slab',
		'more_slabs_stairs_and_walls:chiseled_red_sandstone_stairs',
		'more_slabs_stairs_and_walls:chiseled_red_sandstone_wall',
		'more_slabs_stairs_and_walls:chiseled_sandstone_slab',
		'more_slabs_stairs_and_walls:chiseled_sandstone_stairs',
		'more_slabs_stairs_and_walls:chiseled_sandstone_wall',
		'more_slabs_stairs_and_walls:chiseled_stone_bricks_slab',
		'more_slabs_stairs_and_walls:chiseled_stone_bricks_stairs',
		'more_slabs_stairs_and_walls:chiseled_stone_bricks_wall',
		'more_slabs_stairs_and_walls:clay_slab',
		'more_slabs_stairs_and_walls:clay_stairs',
		'more_slabs_stairs_and_walls:clay_wall',
		'more_slabs_stairs_and_walls:coal_block_slab',
		'more_slabs_stairs_and_walls:coal_block_stairs',
		'more_slabs_stairs_and_walls:coal_block_wall',
		'more_slabs_stairs_and_walls:coarse_dirt_slab',
		'more_slabs_stairs_and_walls:coarse_dirt_stairs',
		'more_slabs_stairs_and_walls:coarse_dirt_wall',
		'more_slabs_stairs_and_walls:copper_block_slab',
		'more_slabs_stairs_and_walls:copper_block_stairs',
		'more_slabs_stairs_and_walls:copper_block_wall',
		'more_slabs_stairs_and_walls:cracked_deepslate_bricks_slab',
		'more_slabs_stairs_and_walls:cracked_deepslate_bricks_stairs',
		'more_slabs_stairs_and_walls:cracked_deepslate_bricks_wall',
		'more_slabs_stairs_and_walls:cracked_deepslate_tiles_slab',
		'more_slabs_stairs_and_walls:cracked_deepslate_tiles_stairs',
		'more_slabs_stairs_and_walls:cracked_deepslate_tiles_wall',
		'more_slabs_stairs_and_walls:cracked_nether_bricks_slab',
		'more_slabs_stairs_and_walls:cracked_nether_bricks_stairs',
		'more_slabs_stairs_and_walls:cracked_nether_bricks_wall',
		'more_slabs_stairs_and_walls:cracked_polished_blackstone_bricks_slab',
		'more_slabs_stairs_and_walls:cracked_polished_blackstone_bricks_stairs',
		'more_slabs_stairs_and_walls:cracked_polished_blackstone_bricks_wall',
		'more_slabs_stairs_and_walls:cracked_stone_bricks_slab',
		'more_slabs_stairs_and_walls:cracked_stone_bricks_stairs',
		'more_slabs_stairs_and_walls:cracked_stone_bricks_wall',
		'more_slabs_stairs_and_walls:crimson_hyphae_slab',
		'more_slabs_stairs_and_walls:crimson_hyphae_stairs',
		'more_slabs_stairs_and_walls:crimson_hyphae_wall',
		'more_slabs_stairs_and_walls:crimson_nylium_slab',
		'more_slabs_stairs_and_walls:crimson_nylium_stairs',
		'more_slabs_stairs_and_walls:crimson_nylium_wall',
		'more_slabs_stairs_and_walls:crimson_planks_wall',
		'more_slabs_stairs_and_walls:crimson_stem_slab',
		'more_slabs_stairs_and_walls:crimson_stem_stairs',
		'more_slabs_stairs_and_walls:crimson_stem_wall',
		'more_slabs_stairs_and_walls:crimson_wart_slab',
		'more_slabs_stairs_and_walls:crimson_wart_stairs',
		'more_slabs_stairs_and_walls:crimson_wart_wall',
		'more_slabs_stairs_and_walls:crying_obsidian_slab',
		'more_slabs_stairs_and_walls:crying_obsidian_stairs',
		'more_slabs_stairs_and_walls:crying_obsidian_wall',
		'more_slabs_stairs_and_walls:cut_copper_wall',
		'more_slabs_stairs_and_walls:cut_red_sandstone_wall',
		'more_slabs_stairs_and_walls:cut_sandstone_wall',
		'more_slabs_stairs_and_walls:cyan_concrete_powder_slab',
		'more_slabs_stairs_and_walls:cyan_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:cyan_concrete_powder_wall',
		'more_slabs_stairs_and_walls:cyan_concrete_slab',
		'more_slabs_stairs_and_walls:cyan_concrete_stairs',
		'more_slabs_stairs_and_walls:cyan_concrete_wall',
		'more_slabs_stairs_and_walls:cyan_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:cyan_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:cyan_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:cyan_stained_glass_slab',
		'more_slabs_stairs_and_walls:cyan_stained_glass_stairs',
		'more_slabs_stairs_and_walls:cyan_stained_glass_wall',
		'more_slabs_stairs_and_walls:cyan_terracotta_slab',
		'more_slabs_stairs_and_walls:cyan_terracotta_stairs',
		'more_slabs_stairs_and_walls:cyan_terracotta_wall',
		'more_slabs_stairs_and_walls:cyan_wool_slab',
		'more_slabs_stairs_and_walls:cyan_wool_stairs',
		'more_slabs_stairs_and_walls:cyan_wool_wall',
		'more_slabs_stairs_and_walls:dark_oak_leaves_slab',
		'more_slabs_stairs_and_walls:dark_oak_leaves_stairs',
		'more_slabs_stairs_and_walls:dark_oak_leaves_wall',
		'more_slabs_stairs_and_walls:dark_oak_log_slab',
		'more_slabs_stairs_and_walls:dark_oak_log_stairs',
		'more_slabs_stairs_and_walls:dark_oak_log_wall',
		'more_slabs_stairs_and_walls:dark_oak_planks_wall',
		'more_slabs_stairs_and_walls:dark_oak_wood_slab',
		'more_slabs_stairs_and_walls:dark_oak_wood_stairs',
		'more_slabs_stairs_and_walls:dark_oak_wood_wall',
		'more_slabs_stairs_and_walls:dark_prismarine_wall',
		'more_slabs_stairs_and_walls:dead_brain_coral_block_slab',
		'more_slabs_stairs_and_walls:dead_brain_coral_block_stairs',
		'more_slabs_stairs_and_walls:dead_brain_coral_block_wall',
		'more_slabs_stairs_and_walls:dead_bubble_coral_block_slab',
		'more_slabs_stairs_and_walls:dead_bubble_coral_block_stairs',
		'more_slabs_stairs_and_walls:dead_bubble_coral_block_wall',
		'more_slabs_stairs_and_walls:dead_fire_coral_block_slab',
		'more_slabs_stairs_and_walls:dead_fire_coral_block_stairs',
		'more_slabs_stairs_and_walls:dead_fire_coral_block_wall',
		'more_slabs_stairs_and_walls:dead_horn_coral_block_slab',
		'more_slabs_stairs_and_walls:dead_horn_coral_block_stairs',
		'more_slabs_stairs_and_walls:dead_horn_coral_block_wall',
		'more_slabs_stairs_and_walls:dead_tube_coral_block_slab',
		'more_slabs_stairs_and_walls:dead_tube_coral_block_stairs',
		'more_slabs_stairs_and_walls:dead_tube_coral_block_wall',
		'more_slabs_stairs_and_walls:deepslate_slab',
		'more_slabs_stairs_and_walls:deepslate_stairs',
		'more_slabs_stairs_and_walls:deepslate_wall',
		'more_slabs_stairs_and_walls:diamond_block_slab',
		'more_slabs_stairs_and_walls:diamond_block_stairs',
		'more_slabs_stairs_and_walls:diamond_block_wall',
		'more_slabs_stairs_and_walls:dirt_path_slab',
		'more_slabs_stairs_and_walls:dirt_path_stairs',
		'more_slabs_stairs_and_walls:dirt_path_wall',
		'more_slabs_stairs_and_walls:dirt_slab',
		'more_slabs_stairs_and_walls:dirt_stairs',
		'more_slabs_stairs_and_walls:dirt_wall',
		'more_slabs_stairs_and_walls:dried_kelp_block_slab',
		'more_slabs_stairs_and_walls:dried_kelp_block_stairs',
		'more_slabs_stairs_and_walls:dried_kelp_block_wall',
		'more_slabs_stairs_and_walls:dripstone_block_slab',
		'more_slabs_stairs_and_walls:dripstone_block_stairs',
		'more_slabs_stairs_and_walls:dripstone_block_wall',
		'more_slabs_stairs_and_walls:emerald_block_slab',
		'more_slabs_stairs_and_walls:emerald_block_stairs',
		'more_slabs_stairs_and_walls:emerald_block_wall',
		'more_slabs_stairs_and_walls:end_stone_slab',
		'more_slabs_stairs_and_walls:end_stone_stairs',
		'more_slabs_stairs_and_walls:end_stone_wall',
		'more_slabs_stairs_and_walls:exposed_copper_slab',
		'more_slabs_stairs_and_walls:exposed_copper_stairs',
		'more_slabs_stairs_and_walls:exposed_copper_wall',
		'more_slabs_stairs_and_walls:exposed_cut_copper_wall',
		'more_slabs_stairs_and_walls:fire_coral_block_slab',
		'more_slabs_stairs_and_walls:fire_coral_block_stairs',
		'more_slabs_stairs_and_walls:fire_coral_block_wall',
		'more_slabs_stairs_and_walls:flowering_azalea_leaves_slab',
		'more_slabs_stairs_and_walls:flowering_azalea_leaves_stairs',
		'more_slabs_stairs_and_walls:flowering_azalea_leaves_wall',
		'more_slabs_stairs_and_walls:gilded_blackstone_slab',
		'more_slabs_stairs_and_walls:gilded_blackstone_stairs',
		'more_slabs_stairs_and_walls:gilded_blackstone_wall',
		'more_slabs_stairs_and_walls:glass_slab',
		'more_slabs_stairs_and_walls:glass_stairs',
		'more_slabs_stairs_and_walls:glass_wall',
		'more_slabs_stairs_and_walls:glowstone_slab',
		'more_slabs_stairs_and_walls:glowstone_stairs',
		'more_slabs_stairs_and_walls:glowstone_wall',
		'more_slabs_stairs_and_walls:gold_block_slab',
		'more_slabs_stairs_and_walls:gold_block_stairs',
		'more_slabs_stairs_and_walls:gold_block_wall',
		'more_slabs_stairs_and_walls:grass_block_slab',
		'more_slabs_stairs_and_walls:grass_block_stairs',
		'more_slabs_stairs_and_walls:grass_block_wall',
		'more_slabs_stairs_and_walls:gravel_slab',
		'more_slabs_stairs_and_walls:gravel_stairs',
		'more_slabs_stairs_and_walls:gravel_wall',
		'more_slabs_stairs_and_walls:gray_concrete_powder_slab',
		'more_slabs_stairs_and_walls:gray_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:gray_concrete_powder_wall',
		'more_slabs_stairs_and_walls:gray_concrete_slab',
		'more_slabs_stairs_and_walls:gray_concrete_stairs',
		'more_slabs_stairs_and_walls:gray_concrete_wall',
		'more_slabs_stairs_and_walls:gray_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:gray_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:gray_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:gray_stained_glass_slab',
		'more_slabs_stairs_and_walls:gray_stained_glass_stairs',
		'more_slabs_stairs_and_walls:gray_stained_glass_wall',
		'more_slabs_stairs_and_walls:gray_terracotta_slab',
		'more_slabs_stairs_and_walls:gray_terracotta_stairs',
		'more_slabs_stairs_and_walls:gray_terracotta_wall',
		'more_slabs_stairs_and_walls:gray_wool_slab',
		'more_slabs_stairs_and_walls:gray_wool_stairs',
		'more_slabs_stairs_and_walls:gray_wool_wall',
		'more_slabs_stairs_and_walls:green_concrete_powder_slab',
		'more_slabs_stairs_and_walls:green_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:green_concrete_powder_wall',
		'more_slabs_stairs_and_walls:green_concrete_slab',
		'more_slabs_stairs_and_walls:green_concrete_stairs',
		'more_slabs_stairs_and_walls:green_concrete_wall',
		'more_slabs_stairs_and_walls:green_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:green_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:green_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:green_stained_glass_slab',
		'more_slabs_stairs_and_walls:green_stained_glass_stairs',
		'more_slabs_stairs_and_walls:green_stained_glass_wall',
		'more_slabs_stairs_and_walls:green_terracotta_slab',
		'more_slabs_stairs_and_walls:green_terracotta_stairs',
		'more_slabs_stairs_and_walls:green_terracotta_wall',
		'more_slabs_stairs_and_walls:green_wool_slab',
		'more_slabs_stairs_and_walls:green_wool_stairs',
		'more_slabs_stairs_and_walls:green_wool_wall',
		'more_slabs_stairs_and_walls:hay_block_slab',
		'more_slabs_stairs_and_walls:hay_block_stairs',
		'more_slabs_stairs_and_walls:hay_block_wall',
		'more_slabs_stairs_and_walls:honey_block_slab',
		'more_slabs_stairs_and_walls:honey_block_stairs',
		'more_slabs_stairs_and_walls:honey_block_wall',
		'more_slabs_stairs_and_walls:honeycomb_block_slab',
		'more_slabs_stairs_and_walls:honeycomb_block_stairs',
		'more_slabs_stairs_and_walls:honeycomb_block_wall',
		'more_slabs_stairs_and_walls:horn_coral_block_slab',
		'more_slabs_stairs_and_walls:horn_coral_block_stairs',
		'more_slabs_stairs_and_walls:horn_coral_block_wall',
		'more_slabs_stairs_and_walls:ice_slab',
		'more_slabs_stairs_and_walls:ice_stairs',
		'more_slabs_stairs_and_walls:ice_wall',
		'more_slabs_stairs_and_walls:iron_block_slab',
		'more_slabs_stairs_and_walls:iron_block_stairs',
		'more_slabs_stairs_and_walls:iron_block_wall',
		'more_slabs_stairs_and_walls:jungle_leaves_slab',
		'more_slabs_stairs_and_walls:jungle_leaves_stairs',
		'more_slabs_stairs_and_walls:jungle_leaves_wall',
		'more_slabs_stairs_and_walls:jungle_log_slab',
		'more_slabs_stairs_and_walls:jungle_log_stairs',
		'more_slabs_stairs_and_walls:jungle_log_wall',
		'more_slabs_stairs_and_walls:jungle_planks_wall',
		'more_slabs_stairs_and_walls:jungle_wood_slab',
		'more_slabs_stairs_and_walls:jungle_wood_stairs',
		'more_slabs_stairs_and_walls:jungle_wood_wall',
		'more_slabs_stairs_and_walls:lapis_block_slab',
		'more_slabs_stairs_and_walls:lapis_block_stairs',
		'more_slabs_stairs_and_walls:lapis_block_wall',
		'more_slabs_stairs_and_walls:light_blue_concrete_powder_slab',
		'more_slabs_stairs_and_walls:light_blue_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:light_blue_concrete_powder_wall',
		'more_slabs_stairs_and_walls:light_blue_concrete_slab',
		'more_slabs_stairs_and_walls:light_blue_concrete_stairs',
		'more_slabs_stairs_and_walls:light_blue_concrete_wall',
		'more_slabs_stairs_and_walls:light_blue_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:light_blue_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:light_blue_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:light_blue_stained_glass_slab',
		'more_slabs_stairs_and_walls:light_blue_stained_glass_stairs',
		'more_slabs_stairs_and_walls:light_blue_stained_glass_wall',
		'more_slabs_stairs_and_walls:light_blue_terracotta_slab',
		'more_slabs_stairs_and_walls:light_blue_terracotta_stairs',
		'more_slabs_stairs_and_walls:light_blue_terracotta_wall',
		'more_slabs_stairs_and_walls:light_blue_wool_slab',
		'more_slabs_stairs_and_walls:light_blue_wool_stairs',
		'more_slabs_stairs_and_walls:light_blue_wool_wall',
		'more_slabs_stairs_and_walls:light_gray_concrete_powder_slab',
		'more_slabs_stairs_and_walls:light_gray_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:light_gray_concrete_powder_wall',
		'more_slabs_stairs_and_walls:light_gray_concrete_slab',
		'more_slabs_stairs_and_walls:light_gray_concrete_stairs',
		'more_slabs_stairs_and_walls:light_gray_concrete_wall',
		'more_slabs_stairs_and_walls:light_gray_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:light_gray_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:light_gray_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:light_gray_stained_glass_slab',
		'more_slabs_stairs_and_walls:light_gray_stained_glass_stairs',
		'more_slabs_stairs_and_walls:light_gray_stained_glass_wall',
		'more_slabs_stairs_and_walls:light_gray_terracotta_slab',
		'more_slabs_stairs_and_walls:light_gray_terracotta_stairs',
		'more_slabs_stairs_and_walls:light_gray_terracotta_wall',
		'more_slabs_stairs_and_walls:light_gray_wool_slab',
		'more_slabs_stairs_and_walls:light_gray_wool_stairs',
		'more_slabs_stairs_and_walls:light_gray_wool_wall',
		'more_slabs_stairs_and_walls:lime_concrete_powder_slab',
		'more_slabs_stairs_and_walls:lime_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:lime_concrete_powder_wall',
		'more_slabs_stairs_and_walls:lime_concrete_slab',
		'more_slabs_stairs_and_walls:lime_concrete_stairs',
		'more_slabs_stairs_and_walls:lime_concrete_wall',
		'more_slabs_stairs_and_walls:lime_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:lime_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:lime_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:lime_stained_glass_slab',
		'more_slabs_stairs_and_walls:lime_stained_glass_stairs',
		'more_slabs_stairs_and_walls:lime_stained_glass_wall',
		'more_slabs_stairs_and_walls:lime_terracotta_slab',
		'more_slabs_stairs_and_walls:lime_terracotta_stairs',
		'more_slabs_stairs_and_walls:lime_terracotta_wall',
		'more_slabs_stairs_and_walls:lime_wool_slab',
		'more_slabs_stairs_and_walls:lime_wool_stairs',
		'more_slabs_stairs_and_walls:lime_wool_wall',
		'more_slabs_stairs_and_walls:magenta_concrete_powder_slab',
		'more_slabs_stairs_and_walls:magenta_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:magenta_concrete_powder_wall',
		'more_slabs_stairs_and_walls:magenta_concrete_slab',
		'more_slabs_stairs_and_walls:magenta_concrete_stairs',
		'more_slabs_stairs_and_walls:magenta_concrete_wall',
		'more_slabs_stairs_and_walls:magenta_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:magenta_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:magenta_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:magenta_stained_glass_slab',
		'more_slabs_stairs_and_walls:magenta_stained_glass_stairs',
		'more_slabs_stairs_and_walls:magenta_stained_glass_wall',
		'more_slabs_stairs_and_walls:magenta_terracotta_slab',
		'more_slabs_stairs_and_walls:magenta_terracotta_stairs',
		'more_slabs_stairs_and_walls:magenta_terracotta_wall',
		'more_slabs_stairs_and_walls:magenta_wool_slab',
		'more_slabs_stairs_and_walls:magenta_wool_stairs',
		'more_slabs_stairs_and_walls:magenta_wool_wall',
		'more_slabs_stairs_and_walls:magma_block_slab',
		'more_slabs_stairs_and_walls:magma_block_stairs',
		'more_slabs_stairs_and_walls:magma_block_wall',
		'more_slabs_stairs_and_walls:mangrove_leaves_slab',
		'more_slabs_stairs_and_walls:mangrove_leaves_stairs',
		'more_slabs_stairs_and_walls:mangrove_leaves_wall',
		'more_slabs_stairs_and_walls:mangrove_log_slab',
		'more_slabs_stairs_and_walls:mangrove_log_stairs',
		'more_slabs_stairs_and_walls:mangrove_log_wall',
		'more_slabs_stairs_and_walls:mangrove_planks_wall',
		'more_slabs_stairs_and_walls:mangrove_roots_slab',
		'more_slabs_stairs_and_walls:mangrove_roots_stairs',
		'more_slabs_stairs_and_walls:mangrove_roots_wall',
		'more_slabs_stairs_and_walls:mangrove_wood_slab',
		'more_slabs_stairs_and_walls:mangrove_wood_stairs',
		'more_slabs_stairs_and_walls:mangrove_wood_wall',
		'more_slabs_stairs_and_walls:melon_slab',
		'more_slabs_stairs_and_walls:melon_stairs',
		'more_slabs_stairs_and_walls:melon_wall',
		'more_slabs_stairs_and_walls:moss_block_slab',
		'more_slabs_stairs_and_walls:moss_block_stairs',
		'more_slabs_stairs_and_walls:moss_block_wall',
		'more_slabs_stairs_and_walls:mud_slab',
		'more_slabs_stairs_and_walls:mud_stairs',
		'more_slabs_stairs_and_walls:mud_wall',
		'more_slabs_stairs_and_walls:muddy_mangrove_roots_slab',
		'more_slabs_stairs_and_walls:muddy_mangrove_roots_stairs',
		'more_slabs_stairs_and_walls:muddy_mangrove_roots_wall',
		'more_slabs_stairs_and_walls:mushroom_stem_slab',
		'more_slabs_stairs_and_walls:mushroom_stem_stairs',
		'more_slabs_stairs_and_walls:mushroom_stem_wall',
		'more_slabs_stairs_and_walls:mycelium_slab',
		'more_slabs_stairs_and_walls:mycelium_stairs',
		'more_slabs_stairs_and_walls:mycelium_wall',
		'more_slabs_stairs_and_walls:netherite_block_slab',
		'more_slabs_stairs_and_walls:netherite_block_stairs',
		'more_slabs_stairs_and_walls:netherite_block_wall',
		'more_slabs_stairs_and_walls:netherrack_slab',
		'more_slabs_stairs_and_walls:netherrack_stairs',
		'more_slabs_stairs_and_walls:netherrack_wall',
		'more_slabs_stairs_and_walls:oak_leaves_slab',
		'more_slabs_stairs_and_walls:oak_leaves_stairs',
		'more_slabs_stairs_and_walls:oak_leaves_wall',
		'more_slabs_stairs_and_walls:oak_log_slab',
		'more_slabs_stairs_and_walls:oak_log_stairs',
		'more_slabs_stairs_and_walls:oak_log_wall',
		'more_slabs_stairs_and_walls:oak_planks_wall',
		'more_slabs_stairs_and_walls:oak_wood_slab',
		'more_slabs_stairs_and_walls:oak_wood_stairs',
		'more_slabs_stairs_and_walls:oak_wood_wall',
		'more_slabs_stairs_and_walls:obsidian_slab',
		'more_slabs_stairs_and_walls:obsidian_stairs',
		'more_slabs_stairs_and_walls:obsidian_wall',
		'more_slabs_stairs_and_walls:ochre_froglight_slab',
		'more_slabs_stairs_and_walls:ochre_froglight_stairs',
		'more_slabs_stairs_and_walls:ochre_froglight_wall',
		'more_slabs_stairs_and_walls:orange_concrete_powder_slab',
		'more_slabs_stairs_and_walls:orange_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:orange_concrete_powder_wall',
		'more_slabs_stairs_and_walls:orange_concrete_slab',
		'more_slabs_stairs_and_walls:orange_concrete_stairs',
		'more_slabs_stairs_and_walls:orange_concrete_wall',
		'more_slabs_stairs_and_walls:orange_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:orange_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:orange_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:orange_stained_glass_slab',
		'more_slabs_stairs_and_walls:orange_stained_glass_stairs',
		'more_slabs_stairs_and_walls:orange_stained_glass_wall',
		'more_slabs_stairs_and_walls:orange_terracotta_slab',
		'more_slabs_stairs_and_walls:orange_terracotta_stairs',
		'more_slabs_stairs_and_walls:orange_terracotta_wall',
		'more_slabs_stairs_and_walls:orange_wool_slab',
		'more_slabs_stairs_and_walls:orange_wool_stairs',
		'more_slabs_stairs_and_walls:orange_wool_wall',
		'more_slabs_stairs_and_walls:oxidized_copper_slab',
		'more_slabs_stairs_and_walls:oxidized_copper_stairs',
		'more_slabs_stairs_and_walls:oxidized_copper_wall',
		'more_slabs_stairs_and_walls:oxidized_cut_copper_wall',
		'more_slabs_stairs_and_walls:packed_ice_slab',
		'more_slabs_stairs_and_walls:packed_ice_stairs',
		'more_slabs_stairs_and_walls:packed_ice_wall',
		'more_slabs_stairs_and_walls:packed_mud_slab',
		'more_slabs_stairs_and_walls:packed_mud_stairs',
		'more_slabs_stairs_and_walls:packed_mud_wall',
		'more_slabs_stairs_and_walls:pearlescent_froglight_slab',
		'more_slabs_stairs_and_walls:pearlescent_froglight_stairs',
		'more_slabs_stairs_and_walls:pearlescent_froglight_wall',
		'more_slabs_stairs_and_walls:pink_concrete_powder_slab',
		'more_slabs_stairs_and_walls:pink_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:pink_concrete_powder_wall',
		'more_slabs_stairs_and_walls:pink_concrete_slab',
		'more_slabs_stairs_and_walls:pink_concrete_stairs',
		'more_slabs_stairs_and_walls:pink_concrete_wall',
		'more_slabs_stairs_and_walls:pink_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:pink_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:pink_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:pink_stained_glass_slab',
		'more_slabs_stairs_and_walls:pink_stained_glass_stairs',
		'more_slabs_stairs_and_walls:pink_stained_glass_wall',
		'more_slabs_stairs_and_walls:pink_terracotta_slab',
		'more_slabs_stairs_and_walls:pink_terracotta_stairs',
		'more_slabs_stairs_and_walls:pink_terracotta_wall',
		'more_slabs_stairs_and_walls:pink_wool_slab',
		'more_slabs_stairs_and_walls:pink_wool_stairs',
		'more_slabs_stairs_and_walls:pink_wool_wall',
		'more_slabs_stairs_and_walls:podzol_slab',
		'more_slabs_stairs_and_walls:podzol_stairs',
		'more_slabs_stairs_and_walls:podzol_wall',
		'more_slabs_stairs_and_walls:polished_andesite_wall',
		'more_slabs_stairs_and_walls:polished_basalt_slab',
		'more_slabs_stairs_and_walls:polished_basalt_stairs',
		'more_slabs_stairs_and_walls:polished_basalt_wall',
		'more_slabs_stairs_and_walls:polished_diorite_wall',
		'more_slabs_stairs_and_walls:polished_granite_wall',
		'more_slabs_stairs_and_walls:prismarine_bricks_wall',
		'more_slabs_stairs_and_walls:pumpkin_slab',
		'more_slabs_stairs_and_walls:pumpkin_stairs',
		'more_slabs_stairs_and_walls:pumpkin_wall',
		'more_slabs_stairs_and_walls:purple_concrete_powder_slab',
		'more_slabs_stairs_and_walls:purple_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:purple_concrete_powder_wall',
		'more_slabs_stairs_and_walls:purple_concrete_slab',
		'more_slabs_stairs_and_walls:purple_concrete_stairs',
		'more_slabs_stairs_and_walls:purple_concrete_wall',
		'more_slabs_stairs_and_walls:purple_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:purple_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:purple_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:purple_stained_glass_slab',
		'more_slabs_stairs_and_walls:purple_stained_glass_stairs',
		'more_slabs_stairs_and_walls:purple_stained_glass_wall',
		'more_slabs_stairs_and_walls:purple_terracotta_slab',
		'more_slabs_stairs_and_walls:purple_terracotta_stairs',
		'more_slabs_stairs_and_walls:purple_terracotta_wall',
		'more_slabs_stairs_and_walls:purple_wool_slab',
		'more_slabs_stairs_and_walls:purple_wool_stairs',
		'more_slabs_stairs_and_walls:purple_wool_wall',
		'more_slabs_stairs_and_walls:purpur_wall',
		'more_slabs_stairs_and_walls:quartz_block_wall',
		'more_slabs_stairs_and_walls:quartz_bricks_slab',
		'more_slabs_stairs_and_walls:quartz_bricks_stairs',
		'more_slabs_stairs_and_walls:quartz_bricks_wall',
		'more_slabs_stairs_and_walls:quartz_pillar_slab',
		'more_slabs_stairs_and_walls:quartz_pillar_stairs',
		'more_slabs_stairs_and_walls:quartz_pillar_wall',
		'more_slabs_stairs_and_walls:raw_copper_block_slab',
		'more_slabs_stairs_and_walls:raw_copper_block_stairs',
		'more_slabs_stairs_and_walls:raw_copper_block_wall',
		'more_slabs_stairs_and_walls:raw_gold_block_slab',
		'more_slabs_stairs_and_walls:raw_gold_block_stairs',
		'more_slabs_stairs_and_walls:raw_gold_block_wall',
		'more_slabs_stairs_and_walls:raw_iron_block_slab',
		'more_slabs_stairs_and_walls:raw_iron_block_stairs',
		'more_slabs_stairs_and_walls:raw_iron_block_wall',
		'more_slabs_stairs_and_walls:red_concrete_powder_slab',
		'more_slabs_stairs_and_walls:red_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:red_concrete_powder_wall',
		'more_slabs_stairs_and_walls:red_concrete_slab',
		'more_slabs_stairs_and_walls:red_concrete_stairs',
		'more_slabs_stairs_and_walls:red_concrete_wall',
		'more_slabs_stairs_and_walls:red_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:red_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:red_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:red_mushroom_block_slab',
		'more_slabs_stairs_and_walls:red_mushroom_block_stairs',
		'more_slabs_stairs_and_walls:red_mushroom_block_wall',
		'more_slabs_stairs_and_walls:red_sand_slab',
		'more_slabs_stairs_and_walls:red_sand_stairs',
		'more_slabs_stairs_and_walls:red_sand_wall',
		'more_slabs_stairs_and_walls:red_stained_glass_slab',
		'more_slabs_stairs_and_walls:red_stained_glass_stairs',
		'more_slabs_stairs_and_walls:red_stained_glass_wall',
		'more_slabs_stairs_and_walls:red_terracotta_slab',
		'more_slabs_stairs_and_walls:red_terracotta_stairs',
		'more_slabs_stairs_and_walls:red_terracotta_wall',
		'more_slabs_stairs_and_walls:red_wool_slab',
		'more_slabs_stairs_and_walls:red_wool_stairs',
		'more_slabs_stairs_and_walls:red_wool_wall',
		'more_slabs_stairs_and_walls:redstone_block_slab',
		'more_slabs_stairs_and_walls:redstone_block_stairs',
		'more_slabs_stairs_and_walls:redstone_block_wall',
		'more_slabs_stairs_and_walls:rooted_dirt_slab',
		'more_slabs_stairs_and_walls:rooted_dirt_stairs',
		'more_slabs_stairs_and_walls:rooted_dirt_wall',
		'more_slabs_stairs_and_walls:sand_slab',
		'more_slabs_stairs_and_walls:sand_stairs',
		'more_slabs_stairs_and_walls:sand_wall',
		'more_slabs_stairs_and_walls:sculk_slab',
		'more_slabs_stairs_and_walls:sculk_stairs',
		'more_slabs_stairs_and_walls:sculk_wall',
		'more_slabs_stairs_and_walls:sea_lantern_slab',
		'more_slabs_stairs_and_walls:sea_lantern_stairs',
		'more_slabs_stairs_and_walls:sea_lantern_wall',
		'more_slabs_stairs_and_walls:shroomlight_slab',
		'more_slabs_stairs_and_walls:shroomlight_stairs',
		'more_slabs_stairs_and_walls:shroomlight_wall',
		'more_slabs_stairs_and_walls:slime_block_slab',
		'more_slabs_stairs_and_walls:slime_block_stairs',
		'more_slabs_stairs_and_walls:slime_block_wall',
		'more_slabs_stairs_and_walls:smooth_basalt_slab',
		'more_slabs_stairs_and_walls:smooth_basalt_stairs',
		'more_slabs_stairs_and_walls:smooth_basalt_wall',
		'more_slabs_stairs_and_walls:smooth_quartz_slab',
		'more_slabs_stairs_and_walls:smooth_quartz_stairs',
		'more_slabs_stairs_and_walls:smooth_quartz_wall',
		'more_slabs_stairs_and_walls:smooth_red_sandstone_wall',
		'more_slabs_stairs_and_walls:smooth_sandstone_wall',
		'more_slabs_stairs_and_walls:smooth_stone_wall',
		'more_slabs_stairs_and_walls:snow_block_slab',
		'more_slabs_stairs_and_walls:snow_block_stairs',
		'more_slabs_stairs_and_walls:snow_block_wall',
		'more_slabs_stairs_and_walls:soul_sand_slab',
		'more_slabs_stairs_and_walls:soul_sand_stairs',
		'more_slabs_stairs_and_walls:soul_sand_wall',
		'more_slabs_stairs_and_walls:soul_soil_slab',
		'more_slabs_stairs_and_walls:soul_soil_stairs',
		'more_slabs_stairs_and_walls:soul_soil_wall',
		'more_slabs_stairs_and_walls:spruce_leaves_slab',
		'more_slabs_stairs_and_walls:spruce_leaves_stairs',
		'more_slabs_stairs_and_walls:spruce_leaves_wall',
		'more_slabs_stairs_and_walls:spruce_log_slab',
		'more_slabs_stairs_and_walls:spruce_log_stairs',
		'more_slabs_stairs_and_walls:spruce_log_wall',
		'more_slabs_stairs_and_walls:spruce_planks_wall',
		'more_slabs_stairs_and_walls:spruce_wood_slab',
		'more_slabs_stairs_and_walls:spruce_wood_stairs',
		'more_slabs_stairs_and_walls:spruce_wood_wall',
		'more_slabs_stairs_and_walls:stone_wall',
		'more_slabs_stairs_and_walls:stripped_acacia_log_slab',
		'more_slabs_stairs_and_walls:stripped_acacia_log_stairs',
		'more_slabs_stairs_and_walls:stripped_acacia_log_wall',
		'more_slabs_stairs_and_walls:stripped_acacia_wood_slab',
		'more_slabs_stairs_and_walls:stripped_acacia_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_acacia_wood_wall',
		'more_slabs_stairs_and_walls:stripped_bamboo_block_slab',
		'more_slabs_stairs_and_walls:stripped_bamboo_block_stairs',
		'more_slabs_stairs_and_walls:stripped_bamboo_block_wall',
		'more_slabs_stairs_and_walls:stripped_birch_log_slab',
		'more_slabs_stairs_and_walls:stripped_birch_log_stairs',
		'more_slabs_stairs_and_walls:stripped_birch_log_wall',
		'more_slabs_stairs_and_walls:stripped_birch_wood_slab',
		'more_slabs_stairs_and_walls:stripped_birch_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_birch_wood_wall',
		'more_slabs_stairs_and_walls:stripped_cherry_log_slab',
		'more_slabs_stairs_and_walls:stripped_cherry_log_stairs',
		'more_slabs_stairs_and_walls:stripped_cherry_log_wall',
		'more_slabs_stairs_and_walls:stripped_cherry_wood_slab',
		'more_slabs_stairs_and_walls:stripped_cherry_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_cherry_wood_wall',
		'more_slabs_stairs_and_walls:stripped_crimson_hyphae_slab',
		'more_slabs_stairs_and_walls:stripped_crimson_hyphae_stairs',
		'more_slabs_stairs_and_walls:stripped_crimson_hyphae_wall',
		'more_slabs_stairs_and_walls:stripped_crimson_stem_slab',
		'more_slabs_stairs_and_walls:stripped_crimson_stem_stairs',
		'more_slabs_stairs_and_walls:stripped_crimson_stem_wall',
		'more_slabs_stairs_and_walls:stripped_dark_oak_log_slab',
		'more_slabs_stairs_and_walls:stripped_dark_oak_log_stairs',
		'more_slabs_stairs_and_walls:stripped_dark_oak_log_wall',
		'more_slabs_stairs_and_walls:stripped_dark_oak_wood_slab',
		'more_slabs_stairs_and_walls:stripped_dark_oak_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_dark_oak_wood_wall',
		'more_slabs_stairs_and_walls:stripped_jungle_log_slab',
		'more_slabs_stairs_and_walls:stripped_jungle_log_stairs',
		'more_slabs_stairs_and_walls:stripped_jungle_log_wall',
		'more_slabs_stairs_and_walls:stripped_jungle_wood_slab',
		'more_slabs_stairs_and_walls:stripped_jungle_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_jungle_wood_wall',
		'more_slabs_stairs_and_walls:stripped_mangrove_log_slab',
		'more_slabs_stairs_and_walls:stripped_mangrove_log_stairs',
		'more_slabs_stairs_and_walls:stripped_mangrove_log_wall',
		'more_slabs_stairs_and_walls:stripped_mangrove_wood_slab',
		'more_slabs_stairs_and_walls:stripped_mangrove_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_mangrove_wood_wall',
		'more_slabs_stairs_and_walls:stripped_oak_log_slab',
		'more_slabs_stairs_and_walls:stripped_oak_log_stairs',
		'more_slabs_stairs_and_walls:stripped_oak_log_wall',
		'more_slabs_stairs_and_walls:stripped_oak_wood_slab',
		'more_slabs_stairs_and_walls:stripped_oak_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_oak_wood_wall',
		'more_slabs_stairs_and_walls:stripped_spruce_log_slab',
		'more_slabs_stairs_and_walls:stripped_spruce_log_stairs',
		'more_slabs_stairs_and_walls:stripped_spruce_log_wall',
		'more_slabs_stairs_and_walls:stripped_spruce_wood_slab',
		'more_slabs_stairs_and_walls:stripped_spruce_wood_stairs',
		'more_slabs_stairs_and_walls:stripped_spruce_wood_wall',
		'more_slabs_stairs_and_walls:stripped_warped_hyphae_slab',
		'more_slabs_stairs_and_walls:stripped_warped_hyphae_stairs',
		'more_slabs_stairs_and_walls:stripped_warped_hyphae_wall',
		'more_slabs_stairs_and_walls:stripped_warped_stem_slab',
		'more_slabs_stairs_and_walls:stripped_warped_stem_stairs',
		'more_slabs_stairs_and_walls:stripped_warped_stem_wall',
		'more_slabs_stairs_and_walls:terracotta_slab',
		'more_slabs_stairs_and_walls:terracotta_stairs',
		'more_slabs_stairs_and_walls:terracotta_wall',
		'more_slabs_stairs_and_walls:tube_coral_block_slab',
		'more_slabs_stairs_and_walls:tube_coral_block_stairs',
		'more_slabs_stairs_and_walls:tube_coral_block_wall',
		'more_slabs_stairs_and_walls:tuff_slab',
		'more_slabs_stairs_and_walls:tuff_stairs',
		'more_slabs_stairs_and_walls:tuff_wall',
		'more_slabs_stairs_and_walls:verdant_froglight_slab',
		'more_slabs_stairs_and_walls:verdant_froglight_stairs',
		'more_slabs_stairs_and_walls:verdant_froglight_wall',
		'more_slabs_stairs_and_walls:warped_hyphae_slab',
		'more_slabs_stairs_and_walls:warped_hyphae_stairs',
		'more_slabs_stairs_and_walls:warped_hyphae_wall',
		'more_slabs_stairs_and_walls:warped_nylium_slab',
		'more_slabs_stairs_and_walls:warped_nylium_stairs',
		'more_slabs_stairs_and_walls:warped_nylium_wall',
		'more_slabs_stairs_and_walls:warped_planks_wall',
		'more_slabs_stairs_and_walls:warped_stem_slab',
		'more_slabs_stairs_and_walls:warped_stem_stairs',
		'more_slabs_stairs_and_walls:warped_stem_wall',
		'more_slabs_stairs_and_walls:warped_wart_slab',
		'more_slabs_stairs_and_walls:warped_wart_stairs',
		'more_slabs_stairs_and_walls:warped_wart_wall',
		'more_slabs_stairs_and_walls:waxed_copper_block_slab',
		'more_slabs_stairs_and_walls:waxed_copper_block_stairs',
		'more_slabs_stairs_and_walls:waxed_copper_block_wall',
		'more_slabs_stairs_and_walls:waxed_cut_copper_wall',
		'more_slabs_stairs_and_walls:waxed_exposed_copper_slab',
		'more_slabs_stairs_and_walls:waxed_exposed_copper_stairs',
		'more_slabs_stairs_and_walls:waxed_exposed_copper_wall',
		'more_slabs_stairs_and_walls:waxed_exposed_cut_copper_wall',
		'more_slabs_stairs_and_walls:waxed_oxidized_copper_slab',
		'more_slabs_stairs_and_walls:waxed_oxidized_copper_stairs',
		'more_slabs_stairs_and_walls:waxed_oxidized_copper_wall',
		'more_slabs_stairs_and_walls:waxed_oxidized_cut_copper_wall',
		'more_slabs_stairs_and_walls:waxed_weathered_copper_slab',
		'more_slabs_stairs_and_walls:waxed_weathered_copper_stairs',
		'more_slabs_stairs_and_walls:waxed_weathered_copper_wall',
		'more_slabs_stairs_and_walls:waxed_weathered_cut_copper_wall',
		'more_slabs_stairs_and_walls:weathered_copper_slab',
		'more_slabs_stairs_and_walls:weathered_copper_stairs',
		'more_slabs_stairs_and_walls:weathered_copper_wall',
		'more_slabs_stairs_and_walls:weathered_cut_copper_wall',
		'more_slabs_stairs_and_walls:white_concrete_powder_slab',
		'more_slabs_stairs_and_walls:white_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:white_concrete_powder_wall',
		'more_slabs_stairs_and_walls:white_concrete_slab',
		'more_slabs_stairs_and_walls:white_concrete_stairs',
		'more_slabs_stairs_and_walls:white_concrete_wall',
		'more_slabs_stairs_and_walls:white_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:white_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:white_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:white_stained_glass_slab',
		'more_slabs_stairs_and_walls:white_stained_glass_stairs',
		'more_slabs_stairs_and_walls:white_stained_glass_wall',
		'more_slabs_stairs_and_walls:white_terracotta_slab',
		'more_slabs_stairs_and_walls:white_terracotta_stairs',
		'more_slabs_stairs_and_walls:white_terracotta_wall',
		'more_slabs_stairs_and_walls:white_wool_slab',
		'more_slabs_stairs_and_walls:white_wool_stairs',
		'more_slabs_stairs_and_walls:white_wool_wall',
		'more_slabs_stairs_and_walls:yellow_concrete_powder_slab',
		'more_slabs_stairs_and_walls:yellow_concrete_powder_stairs',
		'more_slabs_stairs_and_walls:yellow_concrete_powder_wall',
		'more_slabs_stairs_and_walls:yellow_concrete_slab',
		'more_slabs_stairs_and_walls:yellow_concrete_stairs',
		'more_slabs_stairs_and_walls:yellow_concrete_wall',
		'more_slabs_stairs_and_walls:yellow_glazed_terracotta_slab',
		'more_slabs_stairs_and_walls:yellow_glazed_terracotta_stairs',
		'more_slabs_stairs_and_walls:yellow_glazed_terracotta_wall',
		'more_slabs_stairs_and_walls:yellow_stained_glass_slab',
		'more_slabs_stairs_and_walls:yellow_stained_glass_stairs',
		'more_slabs_stairs_and_walls:yellow_stained_glass_wall',
		'more_slabs_stairs_and_walls:yellow_terracotta_slab',
		'more_slabs_stairs_and_walls:yellow_terracotta_stairs',
		'more_slabs_stairs_and_walls:yellow_terracotta_wall',
		'more_slabs_stairs_and_walls:yellow_wool_slab',
		'more_slabs_stairs_and_walls:yellow_wool_stairs',
		'more_slabs_stairs_and_walls:yellow_wool_wall',
		'quark:acacia_bookshelf',
		'quark:acacia_chest',
		'quark:acacia_hedge',
		'quark:acacia_ladder',
		'quark:acacia_leaf_carpet',
		'quark:acacia_post',
		'quark:acacia_trapped_chest',
		'quark:acacia_vertical_slab',
		'quark:ancient_bookshelf',
		'quark:ancient_button',
		'quark:ancient_chest',
		'quark:ancient_door',
		'quark:ancient_fence',
		'quark:ancient_fence_gate',
		'quark:ancient_hanging_sign',
		'quark:ancient_hedge',
		'quark:ancient_ladder',
		'quark:ancient_leaf_carpet',
		'quark:ancient_leaves',
		'quark:ancient_log',
		'quark:ancient_planks',
		'quark:ancient_planks_slab',
		'quark:ancient_planks_stairs',
		'quark:ancient_planks_vertical_slab',
		'quark:ancient_post',
		'quark:ancient_pressure_plate',
		'quark:ancient_sapling',
		'quark:ancient_sign',
		'quark:ancient_trapdoor',
		'quark:ancient_trapped_chest',
		'quark:ancient_wall_hanging_sign',
		'quark:ancient_wall_sign',
		'quark:ancient_wood',
		'quark:andesite_bricks',
		'quark:andesite_bricks_slab',
		'quark:andesite_bricks_stairs',
		'quark:andesite_bricks_vertical_slab',
		'quark:andesite_bricks_wall',
		'quark:andesite_pillar',
		'quark:andesite_vertical_slab',
		'quark:apple_crate',
		'quark:azalea_bookshelf',
		'quark:azalea_button',
		'quark:azalea_chest',
		'quark:azalea_door',
		'quark:azalea_fence',
		'quark:azalea_fence_gate',
		'quark:azalea_hanging_sign',
		'quark:azalea_hedge',
		'quark:azalea_ladder',
		'quark:azalea_leaf_carpet',
		'quark:azalea_log',
		'quark:azalea_planks',
		'quark:azalea_planks_slab',
		'quark:azalea_planks_stairs',
		'quark:azalea_planks_vertical_slab',
		'quark:azalea_post',
		'quark:azalea_pressure_plate',
		'quark:azalea_sign',
		'quark:azalea_trapdoor',
		'quark:azalea_trapped_chest',
		'quark:azalea_wall_hanging_sign',
		'quark:azalea_wall_sign',
		'quark:azalea_wood',
		'quark:bamboo_bookshelf',
		'quark:bamboo_chest',
		'quark:bamboo_ladder',
		'quark:bamboo_mat',
		'quark:bamboo_mat_carpet',
		'quark:bamboo_mosaic_vertical_slab',
		'quark:bamboo_post',
		'quark:bamboo_trapped_chest',
		'quark:bamboo_vertical_slab',
		'quark:beetroot_crate',
		'quark:berry_sack',
		'quark:birch_bookshelf',
		'quark:birch_chest',
		'quark:birch_hedge',
		'quark:birch_ladder',
		'quark:birch_leaf_carpet',
		'quark:birch_post',
		'quark:birch_trapped_chest',
		'quark:birch_vertical_slab',
		'quark:black_corundum',
		'quark:black_corundum_cluster',
		'quark:black_corundum_pane',
		'quark:black_crystal_lamp',
		'quark:black_framed_glass',
		'quark:black_framed_glass_pane',
		'quark:black_shingles',
		'quark:black_shingles_slab',
		'quark:black_shingles_stairs',
		'quark:black_shingles_vertical_slab',
		'quark:black_stool',
		'quark:blackstone_bricks',
		'quark:blackstone_bricks_slab',
		'quark:blackstone_bricks_stairs',
		'quark:blackstone_bricks_vertical_slab',
		'quark:blackstone_bricks_wall',
		'quark:blackstone_furnace',
		'quark:blackstone_vertical_slab',
		'quark:blaze_lantern',
		'quark:blossom_bookshelf',
		'quark:blossom_button',
		'quark:blossom_chest',
		'quark:blossom_door',
		'quark:blossom_fence',
		'quark:blossom_fence_gate',
		'quark:blossom_hanging_sign',
		'quark:blossom_ladder',
		'quark:blossom_log',
		'quark:blossom_planks',
		'quark:blossom_planks_slab',
		'quark:blossom_planks_stairs',
		'quark:blossom_planks_vertical_slab',
		'quark:blossom_post',
		'quark:blossom_pressure_plate',
		'quark:blossom_sign',
		'quark:blossom_trapdoor',
		'quark:blossom_trapped_chest',
		'quark:blossom_wall_hanging_sign',
		'quark:blossom_wall_sign',
		'quark:blossom_wood',
		'quark:blue_blossom_hedge',
		'quark:blue_blossom_leaf_carpet',
		'quark:blue_blossom_leaves',
		'quark:blue_blossom_sapling',
		'quark:blue_corundum',
		'quark:blue_corundum_cluster',
		'quark:blue_corundum_pane',
		'quark:blue_crystal_lamp',
		'quark:blue_framed_glass',
		'quark:blue_framed_glass_pane',
		'quark:blue_nether_bricks',
		'quark:blue_nether_bricks_slab',
		'quark:blue_nether_bricks_stairs',
		'quark:blue_nether_bricks_vertical_slab',
		'quark:blue_nether_bricks_wall',
		'quark:blue_shingles',
		'quark:blue_shingles_slab',
		'quark:blue_shingles_stairs',
		'quark:blue_shingles_vertical_slab',
		'quark:blue_stool',
		'quark:bonded_leather',
		'quark:bonded_rabbit_hide',
		'quark:bonded_ravager_hide',
		'quark:brick_vertical_slab',
		'quark:brown_framed_glass',
		'quark:brown_framed_glass_pane',
		'quark:brown_shingles',
		'quark:brown_shingles_slab',
		'quark:brown_shingles_stairs',
		'quark:brown_shingles_vertical_slab',
		'quark:brown_stool',
		'quark:cactus_block',
		'quark:calcite_bricks',
		'quark:calcite_bricks_slab',
		'quark:calcite_bricks_stairs',
		'quark:calcite_bricks_vertical_slab',
		'quark:calcite_bricks_wall',
		'quark:calcite_pillar',
		'quark:calcite_slab',
		'quark:calcite_stairs',
		'quark:calcite_vertical_slab',
		'quark:calcite_wall',
		'quark:carrot_crate',
		'quark:carved_mud_bricks',
		'quark:charcoal_block',
		'quark:cherry_bookshelf',
		'quark:cherry_chest',
		'quark:cherry_hedge',
		'quark:cherry_ladder',
		'quark:cherry_leaf_carpet',
		'quark:cherry_post',
		'quark:cherry_trapped_chest',
		'quark:cherry_vertical_slab',
		'quark:chiseled_andesite_bricks',
		'quark:chiseled_calcite_bricks',
		'quark:chiseled_diorite_bricks',
		'quark:chiseled_dripstone_bricks',
		'quark:chiseled_granite_bricks',
		'quark:chiseled_jasper_bricks',
		'quark:chiseled_limestone_bricks',
		'quark:chiseled_myalite_bricks',
		'quark:chiseled_shale_bricks',
		'quark:chiseled_soul_sandstone',
		'quark:chiseled_tuff_bricks',
		'quark:chorus_fruit_block',
		'quark:chorus_twist',
		'quark:chorus_weeds',
		'quark:chute',
		'quark:cloud',
		'quark:cobbled_deepslate_vertical_slab',
		'quark:cobblestone_bricks',
		'quark:cobblestone_bricks_slab',
		'quark:cobblestone_bricks_stairs',
		'quark:cobblestone_bricks_vertical_slab',
		'quark:cobblestone_bricks_wall',
		'quark:cobblestone_vertical_slab',
		'quark:cocoa_beans_sack',
		'quark:crafter',
		'quark:crate',
		'quark:crimson_bookshelf',
		'quark:crimson_chest',
		'quark:crimson_ladder',
		'quark:crimson_post',
		'quark:crimson_trapped_chest',
		'quark:crimson_vertical_slab',
		'quark:cut_copper_vertical_slab',
		'quark:cut_red_sandstone_vertical_slab',
		'quark:cut_sandstone_vertical_slab',
		'quark:cut_soul_sandstone',
		'quark:cut_soul_sandstone_slab',
		'quark:cut_soul_sandstone_vertical_slab',
		'quark:cut_vine',
		'quark:cyan_framed_glass',
		'quark:cyan_framed_glass_pane',
		'quark:cyan_shingles',
		'quark:cyan_shingles_slab',
		'quark:cyan_shingles_stairs',
		'quark:cyan_shingles_vertical_slab',
		'quark:cyan_stool',
		'quark:dark_oak_bookshelf',
		'quark:dark_oak_chest',
		'quark:dark_oak_hedge',
		'quark:dark_oak_ladder',
		'quark:dark_oak_leaf_carpet',
		'quark:dark_oak_post',
		'quark:dark_oak_trapped_chest',
		'quark:dark_oak_vertical_slab',
		'quark:dark_prismarine_vertical_slab',
		'quark:deepslate_brick_vertical_slab',
		'quark:deepslate_furnace',
		'quark:deepslate_tile_vertical_slab',
		'quark:diorite_bricks',
		'quark:diorite_bricks_slab',
		'quark:diorite_bricks_stairs',
		'quark:diorite_bricks_vertical_slab',
		'quark:diorite_bricks_wall',
		'quark:diorite_pillar',
		'quark:diorite_vertical_slab',
		'quark:dirt_bricks',
		'quark:dirt_bricks_slab',
		'quark:dirt_bricks_stairs',
		'quark:dirt_bricks_vertical_slab',
		'quark:dirt_bricks_wall',
		'quark:dirty_glass',
		'quark:dirty_glass_pane',
		'quark:dripstone_block_slab',
		'quark:dripstone_block_stairs',
		'quark:dripstone_block_vertical_slab',
		'quark:dripstone_block_wall',
		'quark:dripstone_bricks',
		'quark:dripstone_bricks_slab',
		'quark:dripstone_bricks_stairs',
		'quark:dripstone_bricks_vertical_slab',
		'quark:dripstone_bricks_wall',
		'quark:dripstone_pillar',
		'quark:duskbound_block',
		'quark:duskbound_block_slab',
		'quark:duskbound_block_stairs',
		'quark:duskbound_block_vertical_slab',
		'quark:duskbound_lantern',
		'quark:dusky_myalite',
		'quark:encased_pipe',
		'quark:end_stone_brick_vertical_slab',
		'quark:ender_watcher',
		'quark:exposed_cut_copper_vertical_slab',
		'quark:feeding_trough',
		'quark:flowering_azalea_hedge',
		'quark:flowering_azalea_leaf_carpet',
		'quark:framed_glass',
		'quark:framed_glass_pane',
		'quark:glow_lichen_growth',
		'quark:glow_shroom',
		'quark:glow_shroom_block',
		'quark:glow_shroom_ring',
		'quark:glow_shroom_stem',
		'quark:glowberry_sack',
		'quark:gold_bars',
		'quark:gold_button',
		'quark:golden_apple_crate',
		'quark:golden_carrot_crate',
		'quark:granite_bricks',
		'quark:granite_bricks_slab',
		'quark:granite_bricks_stairs',
		'quark:granite_bricks_vertical_slab',
		'quark:granite_bricks_wall',
		'quark:granite_pillar',
		'quark:granite_vertical_slab',
		'quark:grate',
		'quark:gravisand',
		'quark:gray_framed_glass',
		'quark:gray_framed_glass_pane',
		'quark:gray_shingles',
		'quark:gray_shingles_slab',
		'quark:gray_shingles_stairs',
		'quark:gray_shingles_vertical_slab',
		'quark:gray_stool',
		'quark:green_corundum',
		'quark:green_corundum_cluster',
		'quark:green_corundum_pane',
		'quark:green_crystal_lamp',
		'quark:green_framed_glass',
		'quark:green_framed_glass_pane',
		'quark:green_shingles',
		'quark:green_shingles_slab',
		'quark:green_shingles_stairs',
		'quark:green_shingles_vertical_slab',
		'quark:green_stool',
		'quark:gunpowder_sack',
		'quark:hollow_acacia_log',
		'quark:hollow_ancient_log',
		'quark:hollow_azalea_log',
		'quark:hollow_birch_log',
		'quark:hollow_blossom_log',
		'quark:hollow_cherry_log',
		'quark:hollow_crimson_stem',
		'quark:hollow_dark_oak_log',
		'quark:hollow_jungle_log',
		'quark:hollow_mangrove_log',
		'quark:hollow_oak_log',
		'quark:hollow_spruce_log',
		'quark:hollow_warped_stem',
		'quark:indigo_corundum',
		'quark:indigo_corundum_cluster',
		'quark:indigo_corundum_pane',
		'quark:indigo_crystal_lamp',
		'quark:iron_button',
		'quark:iron_ladder',
		'quark:iron_pillar',
		'quark:iron_plate',
		'quark:iron_plate_slab',
		'quark:iron_plate_stairs',
		'quark:iron_plate_vertical_slab',
		'quark:iron_rod',
		'quark:jasper',
		'quark:jasper_bricks',
		'quark:jasper_bricks_slab',
		'quark:jasper_bricks_stairs',
		'quark:jasper_bricks_vertical_slab',
		'quark:jasper_bricks_wall',
		'quark:jasper_pillar',
		'quark:jasper_slab',
		'quark:jasper_stairs',
		'quark:jasper_vertical_slab',
		'quark:jasper_wall',
		'quark:jungle_bookshelf',
		'quark:jungle_chest',
		'quark:jungle_hedge',
		'quark:jungle_ladder',
		'quark:jungle_leaf_carpet',
		'quark:jungle_post',
		'quark:jungle_trapped_chest',
		'quark:jungle_vertical_slab',
		'quark:lavender_blossom_hedge',
		'quark:lavender_blossom_leaf_carpet',
		'quark:lavender_blossom_leaves',
		'quark:lavender_blossom_sapling',
		'quark:light_blue_framed_glass',
		'quark:light_blue_framed_glass_pane',
		'quark:light_blue_shingles',
		'quark:light_blue_shingles_slab',
		'quark:light_blue_shingles_stairs',
		'quark:light_blue_shingles_vertical_slab',
		'quark:light_blue_stool',
		'quark:light_gray_framed_glass',
		'quark:light_gray_framed_glass_pane',
		'quark:light_gray_shingles',
		'quark:light_gray_shingles_slab',
		'quark:light_gray_shingles_stairs',
		'quark:light_gray_shingles_vertical_slab',
		'quark:light_gray_stool',
		'quark:lime_framed_glass',
		'quark:lime_framed_glass_pane',
		'quark:lime_shingles',
		'quark:lime_shingles_slab',
		'quark:lime_shingles_stairs',
		'quark:lime_shingles_vertical_slab',
		'quark:lime_stool',
		'quark:limestone',
		'quark:limestone_bricks',
		'quark:limestone_bricks_slab',
		'quark:limestone_bricks_stairs',
		'quark:limestone_bricks_vertical_slab',
		'quark:limestone_bricks_wall',
		'quark:limestone_pillar',
		'quark:limestone_slab',
		'quark:limestone_stairs',
		'quark:limestone_vertical_slab',
		'quark:limestone_wall',
		'quark:magenta_framed_glass',
		'quark:magenta_framed_glass_pane',
		'quark:magenta_shingles',
		'quark:magenta_shingles_slab',
		'quark:magenta_shingles_stairs',
		'quark:magenta_shingles_vertical_slab',
		'quark:magenta_stool',
		'quark:magnet',
		'quark:magnetized_block',
		'quark:mangrove_bookshelf',
		'quark:mangrove_chest',
		'quark:mangrove_hedge',
		'quark:mangrove_ladder',
		'quark:mangrove_leaf_carpet',
		'quark:mangrove_post',
		'quark:mangrove_trapped_chest',
		'quark:mangrove_vertical_slab',
		'quark:matrix_enchanter',
		'quark:midori_block',
		'quark:midori_block_slab',
		'quark:midori_block_stairs',
		'quark:midori_block_vertical_slab',
		'quark:midori_pillar',
		'quark:monster_box',
		'quark:mossy_cobblestone_bricks',
		'quark:mossy_cobblestone_bricks_slab',
		'quark:mossy_cobblestone_bricks_stairs',
		'quark:mossy_cobblestone_bricks_vertical_slab',
		'quark:mossy_cobblestone_bricks_wall',
		'quark:mossy_cobblestone_vertical_slab',
		'quark:mossy_stone_brick_vertical_slab',
		'quark:mud_brick_lattice',
		'quark:mud_brick_vertical_slab',
		'quark:mud_pillar',
		'quark:myalite',
		'quark:myalite_bricks',
		'quark:myalite_bricks_slab',
		'quark:myalite_bricks_stairs',
		'quark:myalite_bricks_vertical_slab',
		'quark:myalite_bricks_wall',
		'quark:myalite_crystal',
		'quark:myalite_pillar',
		'quark:myalite_slab',
		'quark:myalite_stairs',
		'quark:myalite_vertical_slab',
		'quark:myalite_wall',
		'quark:nether_brick_chest',
		'quark:nether_brick_fence_gate',
		'quark:nether_brick_trapped_chest',
		'quark:nether_brick_vertical_slab',
		'quark:nether_wart_sack',
		'quark:netherrack_bricks',
		'quark:netherrack_bricks_slab',
		'quark:netherrack_bricks_stairs',
		'quark:netherrack_bricks_vertical_slab',
		'quark:netherrack_bricks_wall',
		'quark:oak_chest',
		'quark:oak_hedge',
		'quark:oak_leaf_carpet',
		'quark:oak_post',
		'quark:oak_trapped_chest',
		'quark:oak_vertical_slab',
		'quark:obsidian_pressure_plate',
		'quark:orange_blossom_hedge',
		'quark:orange_blossom_leaf_carpet',
		'quark:orange_blossom_leaves',
		'quark:orange_blossom_sapling',
		'quark:orange_corundum',
		'quark:orange_corundum_cluster',
		'quark:orange_corundum_pane',
		'quark:orange_crystal_lamp',
		'quark:orange_framed_glass',
		'quark:orange_framed_glass_pane',
		'quark:orange_shingles',
		'quark:orange_shingles_slab',
		'quark:orange_shingles_stairs',
		'quark:orange_shingles_vertical_slab',
		'quark:orange_stool',
		'quark:oxidized_cut_copper_vertical_slab',
		'quark:paper_lantern',
		'quark:paper_lantern_sakura',
		'quark:paper_wall',
		'quark:paper_wall_big',
		'quark:paper_wall_sakura',
		'quark:permafrost',
		'quark:permafrost_bricks',
		'quark:permafrost_bricks_slab',
		'quark:permafrost_bricks_stairs',
		'quark:permafrost_bricks_vertical_slab',
		'quark:permafrost_bricks_wall',
		'quark:permafrost_slab',
		'quark:permafrost_stairs',
		'quark:permafrost_vertical_slab',
		'quark:permafrost_wall',
		'quark:pink_framed_glass',
		'quark:pink_framed_glass_pane',
		'quark:pink_shingles',
		'quark:pink_shingles_slab',
		'quark:pink_shingles_stairs',
		'quark:pink_shingles_vertical_slab',
		'quark:pink_stool',
		'quark:pipe',
		'quark:polished_andesite_vertical_slab',
		'quark:polished_blackstone_brick_vertical_slab',
		'quark:polished_blackstone_vertical_slab',
		'quark:polished_calcite',
		'quark:polished_calcite_slab',
		'quark:polished_calcite_stairs',
		'quark:polished_calcite_vertical_slab',
		'quark:polished_deepslate_vertical_slab',
		'quark:polished_diorite_vertical_slab',
		'quark:polished_dripstone',
		'quark:polished_dripstone_slab',
		'quark:polished_dripstone_stairs',
		'quark:polished_dripstone_vertical_slab',
		'quark:polished_granite_vertical_slab',
		'quark:polished_jasper',
		'quark:polished_jasper_slab',
		'quark:polished_jasper_stairs',
		'quark:polished_jasper_vertical_slab',
		'quark:polished_limestone',
		'quark:polished_limestone_slab',
		'quark:polished_limestone_stairs',
		'quark:polished_limestone_vertical_slab',
		'quark:polished_myalite',
		'quark:polished_myalite_slab',
		'quark:polished_myalite_stairs',
		'quark:polished_myalite_vertical_slab',
		'quark:polished_shale',
		'quark:polished_shale_slab',
		'quark:polished_shale_stairs',
		'quark:polished_shale_vertical_slab',
		'quark:polished_tuff',
		'quark:polished_tuff_slab',
		'quark:polished_tuff_stairs',
		'quark:polished_tuff_vertical_slab',
		'quark:potato_crate',
		'quark:potted_ancient_sapling',
		'quark:potted_beetroot',
		'quark:potted_berries',
		'quark:potted_blue_blossom_sapling',
		'quark:potted_carrot',
		'quark:potted_cave_vines',
		'quark:potted_chorus',
		'quark:potted_chorus_twist',
		'quark:potted_chorus_weeds',
		'quark:potted_cocoa_bean',
		'quark:potted_glow_lichen_growth',
		'quark:potted_glow_shroom',
		'quark:potted_grass',
		'quark:potted_large_fern',
		'quark:potted_lavender_blossom_sapling',
		'quark:potted_lilac',
		'quark:potted_melon',
		'quark:potted_nether_sprouts',
		'quark:potted_nether_wart',
		'quark:potted_orange_blossom_sapling',
		'quark:potted_peony',
		'quark:potted_pitcher_plant',
		'quark:potted_potato',
		'quark:potted_pumpkin',
		'quark:potted_red_blossom_sapling',
		'quark:potted_rose',
		'quark:potted_sea_pickle',
		'quark:potted_sugar_cane',
		'quark:potted_sunflower',
		'quark:potted_tall_grass',
		'quark:potted_twisting_vines',
		'quark:potted_vine',
		'quark:potted_weeping_vines',
		'quark:potted_wheat',
		'quark:potted_yellow_blossom_sapling',
		'quark:prismarine_brick_vertical_slab',
		'quark:prismarine_chest',
		'quark:prismarine_trapped_chest',
		'quark:prismarine_vertical_slab',
		'quark:purple_framed_glass',
		'quark:purple_framed_glass_pane',
		'quark:purple_shingles',
		'quark:purple_shingles_slab',
		'quark:purple_shingles_stairs',
		'quark:purple_shingles_vertical_slab',
		'quark:purple_stool',
		'quark:purpur_chest',
		'quark:purpur_trapped_chest',
		'quark:purpur_vertical_slab',
		'quark:quartz_vertical_slab',
		'quark:raw_copper_bricks',
		'quark:raw_copper_bricks_slab',
		'quark:raw_copper_bricks_stairs',
		'quark:raw_copper_bricks_vertical_slab',
		'quark:raw_copper_bricks_wall',
		'quark:raw_gold_bricks',
		'quark:raw_gold_bricks_slab',
		'quark:raw_gold_bricks_stairs',
		'quark:raw_gold_bricks_vertical_slab',
		'quark:raw_gold_bricks_wall',
		'quark:raw_iron_bricks',
		'quark:raw_iron_bricks_slab',
		'quark:raw_iron_bricks_stairs',
		'quark:raw_iron_bricks_vertical_slab',
		'quark:raw_iron_bricks_wall',
		'quark:red_blossom_hedge',
		'quark:red_blossom_leaf_carpet',
		'quark:red_blossom_leaves',
		'quark:red_blossom_sapling',
		'quark:red_corundum',
		'quark:red_corundum_cluster',
		'quark:red_corundum_pane',
		'quark:red_crystal_lamp',
		'quark:red_framed_glass',
		'quark:red_framed_glass_pane',
		'quark:red_nether_brick_vertical_slab',
		'quark:red_sandstone_bricks',
		'quark:red_sandstone_bricks_slab',
		'quark:red_sandstone_bricks_stairs',
		'quark:red_sandstone_bricks_vertical_slab',
		'quark:red_sandstone_bricks_wall',
		'quark:red_sandstone_vertical_slab',
		'quark:red_shingles',
		'quark:red_shingles_slab',
		'quark:red_shingles_stairs',
		'quark:red_shingles_vertical_slab',
		'quark:red_stool',
		'quark:redstone_randomizer',
		'quark:rope',
		'quark:rusty_iron_plate',
		'quark:rusty_iron_plate_slab',
		'quark:rusty_iron_plate_stairs',
		'quark:rusty_iron_plate_vertical_slab',
		'quark:sandstone_bricks',
		'quark:sandstone_bricks_slab',
		'quark:sandstone_bricks_stairs',
		'quark:sandstone_bricks_vertical_slab',
		'quark:sandstone_bricks_wall',
		'quark:sandstone_vertical_slab',
		'quark:shale',
		'quark:shale_bricks',
		'quark:shale_bricks_slab',
		'quark:shale_bricks_stairs',
		'quark:shale_bricks_vertical_slab',
		'quark:shale_bricks_wall',
		'quark:shale_pillar',
		'quark:shale_slab',
		'quark:shale_stairs',
		'quark:shale_vertical_slab',
		'quark:shale_wall',
		'quark:shingles',
		'quark:shingles_slab',
		'quark:shingles_stairs',
		'quark:shingles_vertical_slab',
		'quark:smooth_quartz_vertical_slab',
		'quark:smooth_red_sandstone_vertical_slab',
		'quark:smooth_sandstone_vertical_slab',
		'quark:smooth_soul_sandstone',
		'quark:smooth_soul_sandstone_slab',
		'quark:smooth_soul_sandstone_stairs',
		'quark:smooth_soul_sandstone_vertical_slab',
		'quark:smooth_stone_vertical_slab',
		'quark:soul_sandstone',
		'quark:soul_sandstone_bricks',
		'quark:soul_sandstone_bricks_slab',
		'quark:soul_sandstone_bricks_stairs',
		'quark:soul_sandstone_bricks_vertical_slab',
		'quark:soul_sandstone_bricks_wall',
		'quark:soul_sandstone_slab',
		'quark:soul_sandstone_stairs',
		'quark:soul_sandstone_vertical_slab',
		'quark:soul_sandstone_wall',
		'quark:spruce_bookshelf',
		'quark:spruce_chest',
		'quark:spruce_hedge',
		'quark:spruce_ladder',
		'quark:spruce_leaf_carpet',
		'quark:spruce_post',
		'quark:spruce_trapped_chest',
		'quark:spruce_vertical_slab',
		'quark:stick_block',
		'quark:stone_brick_lamp',
		'quark:stone_brick_vertical_slab',
		'quark:stone_lamp',
		'quark:stone_vertical_slab',
		'quark:stripped_acacia_post',
		'quark:stripped_ancient_log',
		'quark:stripped_ancient_post',
		'quark:stripped_ancient_wood',
		'quark:stripped_azalea_log',
		'quark:stripped_azalea_post',
		'quark:stripped_azalea_wood',
		'quark:stripped_bamboo_post',
		'quark:stripped_birch_post',
		'quark:stripped_blossom_log',
		'quark:stripped_blossom_post',
		'quark:stripped_blossom_wood',
		'quark:stripped_cherry_post',
		'quark:stripped_crimson_post',
		'quark:stripped_dark_oak_post',
		'quark:stripped_jungle_post',
		'quark:stripped_mangrove_post',
		'quark:stripped_oak_post',
		'quark:stripped_spruce_post',
		'quark:stripped_warped_post',
		'quark:sturdy_stone',
		'quark:sugar_cane_block',
		'quark:thatch',
		'quark:thatch_slab',
		'quark:thatch_stairs',
		'quark:thatch_vertical_slab',
		'quark:tiny_potato',
		'quark:tuff_bricks',
		'quark:tuff_bricks_slab',
		'quark:tuff_bricks_stairs',
		'quark:tuff_bricks_vertical_slab',
		'quark:tuff_bricks_wall',
		'quark:tuff_pillar',
		'quark:tuff_slab',
		'quark:tuff_stairs',
		'quark:tuff_vertical_slab',
		'quark:tuff_wall',
		'quark:vertical_acacia_planks',
		'quark:vertical_ancient_planks',
		'quark:vertical_azalea_planks',
		'quark:vertical_bamboo_planks',
		'quark:vertical_birch_planks',
		'quark:vertical_blossom_planks',
		'quark:vertical_cherry_planks',
		'quark:vertical_crimson_planks',
		'quark:vertical_dark_oak_planks',
		'quark:vertical_jungle_planks',
		'quark:vertical_mangrove_planks',
		'quark:vertical_oak_planks',
		'quark:vertical_spruce_planks',
		'quark:vertical_warped_planks',
		'quark:violet_corundum',
		'quark:violet_corundum_cluster',
		'quark:violet_corundum_pane',
		'quark:violet_crystal_lamp',
		'quark:warped_bookshelf',
		'quark:warped_chest',
		'quark:warped_ladder',
		'quark:warped_post',
		'quark:warped_trapped_chest',
		'quark:warped_vertical_slab',
		'quark:water_pink_petals',
		'quark:waxed_black_corundum',
		'quark:waxed_blue_corundum',
		'quark:waxed_cut_copper_vertical_slab',
		'quark:waxed_exposed_cut_copper_vertical_slab',
		'quark:waxed_green_corundum',
		'quark:waxed_indigo_corundum',
		'quark:waxed_orange_corundum',
		'quark:waxed_oxidized_cut_copper_vertical_slab',
		'quark:waxed_red_corundum',
		'quark:waxed_violet_corundum',
		'quark:waxed_weathered_cut_copper_vertical_slab',
		'quark:waxed_white_corundum',
		'quark:waxed_yellow_corundum',
		'quark:weathered_cut_copper_vertical_slab',
		'quark:white_corundum',
		'quark:white_corundum_cluster',
		'quark:white_corundum_pane',
		'quark:white_crystal_lamp',
		'quark:white_framed_glass',
		'quark:white_framed_glass_pane',
		'quark:white_shingles',
		'quark:white_shingles_slab',
		'quark:white_shingles_stairs',
		'quark:white_shingles_vertical_slab',
		'quark:white_stool',
		'quark:yellow_blossom_hedge',
		'quark:yellow_blossom_leaf_carpet',
		'quark:yellow_blossom_leaves',
		'quark:yellow_blossom_sapling',
		'quark:yellow_corundum',
		'quark:yellow_corundum_cluster',
		'quark:yellow_corundum_pane',
		'quark:yellow_crystal_lamp',
		'quark:yellow_framed_glass',
		'quark:yellow_framed_glass_pane',
		'quark:yellow_shingles',
		'quark:yellow_shingles_slab',
		'quark:yellow_shingles_stairs',
		'quark:yellow_shingles_vertical_slab',
		'quark:yellow_stool',
		'shardborne:adderstone',
		'shardborne:adderstone_chiseled',
		'shardborne:adderstone_pillar',
		'shardborne:adderstone_slab',
		'shardborne:adderstone_smooth',
		'shardborne:adderstone_stairs',
		'shardborne:application_table',
		'shardborne:aqua_blue_decorative_placeholder',
		'shardborne:aqua_blue_directional_placeholder_block',
		'shardborne:aqua_blue_placeholder_block',
		'shardborne:aqua_blue_placeholder_fence',
		'shardborne:aqua_blue_placeholder_fence_gate',
		'shardborne:aqua_blue_placeholder_slab',
		'shardborne:aqua_blue_placeholder_stairs',
		'shardborne:aqua_blue_placeholder_vertical_slab',
		'shardborne:aqua_blue_placeholder_vine',
		'shardborne:aqua_blue_placeholder_wall',
		'shardborne:baby_blue_decorative_placeholder',
		'shardborne:baby_blue_directional_placeholder_block',
		'shardborne:baby_blue_placeholder_block',
		'shardborne:baby_blue_placeholder_fence',
		'shardborne:baby_blue_placeholder_fence_gate',
		'shardborne:baby_blue_placeholder_slab',
		'shardborne:baby_blue_placeholder_stairs',
		'shardborne:baby_blue_placeholder_vertical_slab',
		'shardborne:baby_blue_placeholder_vine',
		'shardborne:baby_blue_placeholder_wall',
		'shardborne:blue_decorative_placeholder',
		'shardborne:blue_directional_placeholder_block',
		'shardborne:blue_placeholder_block',
		'shardborne:blue_placeholder_fence',
		'shardborne:blue_placeholder_fence_gate',
		'shardborne:blue_placeholder_slab',
		'shardborne:blue_placeholder_stairs',
		'shardborne:blue_placeholder_vertical_slab',
		'shardborne:blue_placeholder_vine',
		'shardborne:blue_placeholder_wall',
		'shardborne:bonfire',
		'shardborne:brasscend',
		'shardborne:brown_decorative_placeholder',
		'shardborne:brown_directional_placeholder_block',
		'shardborne:brown_placeholder_block',
		'shardborne:brown_placeholder_fence',
		'shardborne:brown_placeholder_fence_gate',
		'shardborne:brown_placeholder_slab',
		'shardborne:brown_placeholder_stairs',
		'shardborne:brown_placeholder_vertical_slab',
		'shardborne:brown_placeholder_vine',
		'shardborne:brown_placeholder_wall',
		'shardborne:brown_swamp_moss',
		'shardborne:carving_bench',
		'shardborne:crate_placeholder',
		'shardborne:crying_adderstone',
		'shardborne:dimmed_lantern',
		'shardborne:dungeon_door',
		'shardborne:dungeon_portal',
		'shardborne:dungeoneering_table',
		'shardborne:forge',
		'shardborne:gravestone',
		'shardborne:gray_decorative_placeholder',
		'shardborne:gray_directional_placeholder_block',
		'shardborne:gray_placeholder_block',
		'shardborne:gray_placeholder_fence',
		'shardborne:gray_placeholder_fence_gate',
		'shardborne:gray_placeholder_slab',
		'shardborne:gray_placeholder_stairs',
		'shardborne:gray_placeholder_vertical_slab',
		'shardborne:gray_placeholder_vine',
		'shardborne:gray_placeholder_wall',
		'shardborne:lavender_decorative_placeholder',
		'shardborne:lavender_directional_placeholder_block',
		'shardborne:lavender_placeholder_block',
		'shardborne:lavender_placeholder_fence',
		'shardborne:lavender_placeholder_fence_gate',
		'shardborne:lavender_placeholder_slab',
		'shardborne:lavender_placeholder_stairs',
		'shardborne:lavender_placeholder_vertical_slab',
		'shardborne:lavender_placeholder_vine',
		'shardborne:lavender_placeholder_wall',
		'shardborne:light_green_decorative_placeholder',
		'shardborne:light_green_directional_placeholder_block',
		'shardborne:light_green_placeholder_block',
		'shardborne:light_green_placeholder_fence',
		'shardborne:light_green_placeholder_fence_gate',
		'shardborne:light_green_placeholder_slab',
		'shardborne:light_green_placeholder_stairs',
		'shardborne:light_green_placeholder_vertical_slab',
		'shardborne:light_green_placeholder_vine',
		'shardborne:light_green_placeholder_wall',
		'shardborne:loot_box',
		'shardborne:mint_decorative_placeholder',
		'shardborne:mint_directional_placeholder_block',
		'shardborne:mint_placeholder_block',
		'shardborne:mint_placeholder_fence',
		'shardborne:mint_placeholder_fence_gate',
		'shardborne:mint_placeholder_slab',
		'shardborne:mint_placeholder_stairs',
		'shardborne:mint_placeholder_vertical_slab',
		'shardborne:mint_placeholder_vine',
		'shardborne:mint_placeholder_wall',
		'shardborne:mistroot_bundle',
		'shardborne:mossy_flowers_pink',
		'shardborne:mossy_flowers_yellow',
		'shardborne:mossy_flowers_yellow_pink',
		'shardborne:nexus_portal',
		'shardborne:pink_decorative_placeholder',
		'shardborne:pink_directional_placeholder_block',
		'shardborne:pink_placeholder_block',
		'shardborne:pink_placeholder_fence',
		'shardborne:pink_placeholder_fence_gate',
		'shardborne:pink_placeholder_slab',
		'shardborne:pink_placeholder_stairs',
		'shardborne:pink_placeholder_vertical_slab',
		'shardborne:pink_placeholder_vine',
		'shardborne:pink_placeholder_wall',
		'shardborne:red_decorative_placeholder',
		'shardborne:red_directional_placeholder_block',
		'shardborne:red_placeholder_block',
		'shardborne:red_placeholder_fence',
		'shardborne:red_placeholder_fence_gate',
		'shardborne:red_placeholder_slab',
		'shardborne:red_placeholder_stairs',
		'shardborne:red_placeholder_vertical_slab',
		'shardborne:red_placeholder_vine',
		'shardborne:red_placeholder_wall',
		'shardborne:rhyolite',
		'shardborne:rubble',
		'shardborne:rubble_placeholder',
		'shardborne:sand_decorative_placeholder',
		'shardborne:sand_directional_placeholder_block',
		'shardborne:sand_placeholder_block',
		'shardborne:sand_placeholder_fence',
		'shardborne:sand_placeholder_fence_gate',
		'shardborne:sand_placeholder_slab',
		'shardborne:sand_placeholder_stairs',
		'shardborne:sand_placeholder_vertical_slab',
		'shardborne:sand_placeholder_vine',
		'shardborne:sand_placeholder_wall',
		'shardborne:slateglass_block',
		'shardborne:slime_mold',
		'shardborne:spawner',
		'shardborne:structure_placeholder',
		'shardborne:swamp_moss',
		'shardborne:tealgrass',
		'shardborne:tealgrowth',
		'shardborne:verdigrite',
		'shardborne:yellow_decorative_placeholder',
		'shardborne:yellow_directional_placeholder_block',
		'shardborne:yellow_placeholder_block',
		'shardborne:yellow_placeholder_fence',
		'shardborne:yellow_placeholder_fence_gate',
		'shardborne:yellow_placeholder_slab',
		'shardborne:yellow_placeholder_stairs',
		'shardborne:yellow_placeholder_vertical_slab',
		'shardborne:yellow_placeholder_vine',
		'shardborne:yellow_placeholder_wall',
	])

	collections.register(`${ID}:dimensions`, [
		...collections.get('dimension'),
		'shardborne:realm',
		'shardborne:dungeon',
	])

	schemas.register(
		`${ID}:item_type`,
		ObjectNode({
			id: StringNode({ validator: 'resource', params: { pool: 'block' } }),
			count: Opt(NumberNode({ max: 64 })),
			nbt: Opt(StringNode()),
		})
	)

	schemas.register(
		`${ID}:shardborne_dialogue_type`,
		ListNode(
			ObjectNode({
				type: StringNode({ enum: ['text', 'display_item', 'give_item', 'give_recipe'] }),
				[Switch]: [{ push: 'type' }],
				[Case]: {
					text: {
						text: ListNode(StringNode()),
					},
					give_recipe: {
						recipe: ListNode(StringNode({ validator: 'resource', params: { pool: '$recipe' } })),
					},
					give_item: {
						item: Reference(`${ID}:item_type`),
					},
					display_item: {
						text: ListNode(StringNode()),
						item: Reference(`${ID}:item_type`),
					},
				},
			}),
			{ minLength: 1 }
		)
	)
	schemas.register(
		`${ID}:shardborne_requirement_type`,
		ListNode(
			ObjectNode({
				type: StringNode({ enum: ['enter_dimension', 'locate_structure', 'carry_item', 'make_recipe'] }),
				[Switch]: [{ push: 'type' }],
				[Case]: {
					enter_dimension: {
						dimension: StringNode({ validator: 'resource', params: { pool: `${ID}:dimensions` as any } }),
					},
					locate_structure: {
						structure: StringNode({ validator: 'resource', params: { pool: '$structure' } }),
					},
					carry_item: {
						item: Reference(`${ID}:item_type`),
					},
					make_recipe: {
						item: Reference(`${ID}:item_type`),
					},
				},
			})
		)
	)

	schemas.register(
		`${ID}:shardborne_prerequisites_type`,
		ListNode(
			ObjectNode({
				type: StringNode({ enum: ['questline', 'level'] }),
				[Switch]: [{ push: 'type' }],
				[Case]: {
					questline: {
						questlines: ListNode(StringNode()),
					},
					level: {
						level: NumberNode({ min: 0 }),
					},
				},
			})
		)
	)

	schemas.register(
		'shardborne:custom_npc',
		Mod(
			ObjectNode(
				{
					id: StringNode(),
					prerequisites: Opt(Reference(`${ID}:shardborne_prerequisites_type`)),
					quests: ListNode(
						ObjectNode({
							id: StringNode(),
							npc: Reference(`${ID}:npc_type`),
							initial_dialogue: Reference(`${ID}:shardborne_dialogue_type`),
							finished_dialogue: Opt(Reference(`${ID}:shardborne_dialogue_type`)),
							requirements: Reference(`${ID}:shardborne_requirement_type`),
							satisfied_dialogue: Opt(Reference(`${ID}:shardborne_dialogue_type`)),
							unsatisfied_dialogue: Reference(`${ID}:shardborne_dialogue_type`),
						})
					),
					random_dialogue: ListNode(
						ObjectNode({
							npc: Reference(`${ID}:npc_type`),
							dialogue: Reference(`${ID}:shardborne_dialogue_type`),
						})
					),
				},
				{ context: 'shardborne.custom_npc' }
			),
			{
				default: () => ({
					id: 'questline_one',
				}),
			}
		)
	)

	schemas.register(
		'shardborne:processor_rules',
		ObjectNode(
			{
				processor_type: StringNode({
					enum: [
						'shardborne:dungeon_room_processor',
						'shardborne:block_replacement_processor',
						...collections
							.get('worldgen/structure_processor')
							.filter((value) => value !== 'minecraft:block_rot'),
					],
				}),
				[Switch]: [{ push: 'processor_type' }],
				[Case]: {
					'minecraft:block_age': {
						mossiness: NumberNode(),
					},
					'shardborne:dungeon_room_processor': {
						target: StringNode({ validator: 'resource', params: { pool: 'block' } }),
						success_replacement: StringNode({
							validator: 'resource',
							params: { pool: 'block' },
						}),
						fail_replacement: Opt(StringNode({ validator: 'resource', params: { pool: 'block' } })),
						min_blocks: NumberNode(),
						max_blocks: NumberNode(),
						replacement_chance: NumberNode({ min: 0, max: 1 }),
					},
					'shardborne:block_replacement_processor': {
						input_block: StringNode({
							validator: 'resource',
							params: { pool: 'block' },
						}),
						output_block: StringNode({
							validator: 'resource',
							params: { pool: 'block' },
						}),
						probability: Opt(NumberNode({ min: 0, max: 1 })),
					},
					'minecraft:block_ignore': {
						blocks: ListNode(Reference('block_state')),
					},
					'minecraft:block_rot': {
						integrity: NumberNode({ min: 0, max: 1 }),
						rottable_blocks: Opt(Tag({ resource: 'block' })),
					},
					'minecraft:gravity': {
						heightmap: StringNode({ enum: 'heightmap_type' }),
						offset: NumberNode({ integer: true }),
					},
					'minecraft:protected_blocks': {
						value: StringNode({
							validator: 'resource',
							params: { pool: 'block', requireTag: true },
						}),
					},
					'minecraft:rule': {
						rules: ListNode(Reference('processor_rule')),
					},
				},
			},
			{ context: 'processor', category: 'function' }
		)
	)
	schemas.register(
		'shardborne:processors',
		Mod(
			ObjectNode({
				processors: ListNode(Reference('shardborne:processor_rules')),
			}),
			{
				default: () => ({
					processors: [{ processor_type: 'shardborne:block_replacement_processor' }],
				}),
			}
		)
	)

	const Processors = ChoiceNode([
		{
			type: 'string',
			node: StringNode({ validator: 'resource', params: { pool: '$worldgen/processor_list' } }),
			change: () => undefined,
		},
		{
			type: 'list',
			node: ListNode(Reference('processor')),
			change: (v) =>
				typeof v === 'object' && v !== null && Array.isArray(v.processors)
					? v.processors
					: [{ processor_type: 'minecraft:nop' }],
		},
		{
			type: 'object',
			node: Reference('processor_list'),
			change: (v) => ({
				processors: Array.isArray(v) ? v : [{ processor_type: 'minecraft:nop' }],
			}),
		},
	])

	schemas.register(
		'shardborne:template_element',
		ObjectNode(
			{
				element_type: StringNode({
					enum: ['shardborne:dungeon_pool_element', ...collections.get('worldgen/structure_pool_element')],
				}),
				[Switch]: [{ push: 'element_type' }],
				[Case]: {
					'minecraft:feature_pool_element': {
						projection: StringNode({ enum: ['rigid', 'terrain_matching'] }),
						feature: StringNode({ validator: 'resource', params: { pool: '$worldgen/placed_feature' } }),
					},
					'minecraft:legacy_single_pool_element': {
						projection: StringNode({ enum: ['rigid', 'terrain_matching'] }),
						location: StringNode({ validator: 'resource', params: { pool: '$structure' } }),
						processors: Processors,
					},
					'shardborne:dungeon_pool_element': {
						projection: StringNode({ enum: ['rigid', 'terrain_matching'] }),
						location: StringNode({ validator: 'resource', params: { pool: '$structure' } }),
						processors: ListNode(StringNode()),
						allow_overlap: Mod(Opt(BooleanNode()), { default: () => false }),
						is_room: Mod(Opt(BooleanNode()), { default: () => false }),
						disable_block_replacement: Mod(Opt(BooleanNode()), { default: () => false }),
					},
					'minecraft:list_pool_element': {
						projection: StringNode({ enum: ['rigid', 'terrain_matching'] }),
						elements: ListNode(Reference('template_element')),
					},
					'minecraft:single_pool_element': {
						projection: StringNode({ enum: ['rigid', 'terrain_matching'] }),
						location: StringNode({ validator: 'resource', params: { pool: '$structure' } }),
						processors: Processors,
					},
				},
			},
			{ context: 'template_element', category: 'function' }
		)
	)

	schemas.register(
		'shardborne:theme',
		Mod(
			ObjectNode(
				{
					processor: StringNode({ validator: 'resource', params: { pool: '$worldgen/processor_list' } }),
					weight: NumberNode({ integer: true, min: 1, max: 100 }),
					generation_type: ChoiceNode([
						{
							type: 'string',
							node: StringNode({ enum: ['open_world', 'maze'] }),
							change: () => undefined,
						},
					]),
					biome: StringNode({ validator: 'resource', params: { pool: '$worldgen/biome' } }),
					structures: MapNode(
						StringNode({ validator: 'resource', params: { pool: '$structure' } }),
						ObjectNode({
							min: NumberNode({ integer: true }),
							max: NumberNode({ integer: true }),
						})
					),
					theme_settings_id: StringNode(),
				},
				{ context: 'shardborne.themes' }
			),
			{
				default: () => ({}),
			}
		)
	)

	schemas.register(
		'shardborne:template_pools',
		Mod(
			ObjectNode({
				fallback: StringNode(),
				elements: ListNode(
					ObjectNode({
						weight: NumberNode({ integer: true, min: 1, max: 150 }),
						element: Reference('shardborne:template_element'),
					})
				),
			}),
			{
				default: () => ({
					fallback: 'minecraft:empty',
					elements: [
						{
							weight: 1,
							element: {
								element_type: 'minecraft:single_pool_element',
								projection: 'rigid',
								processors: 'minecraft:empty',
							},
						},
					],
				}),
			}
		)
	)
}
